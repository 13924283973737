import { StudyCycleEnum, StudyProgramApi, StudyProgramDTO, StudyProgramQueryParams, StudyProgramUpdateDTO } from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new StudyProgramApi(getAuthConfiguration());

export const getStudyProgram = (id: string) => {
    if (!id) {
        return {} as StudyProgramDTO;
    }
    return ApiFactory().apiStudyProgramGetStudyProgramByIdIdGet({ id });
}

export const getFilterOptions = (studyCycle: StudyCycleEnum) => {
    return ApiFactory().apiStudyProgramGetFilterOptionsGet({studyCycle});
}

export const getStudyPrograms = (studyProgramQueryParams: StudyProgramQueryParams) => {
    return ApiFactory().apiStudyProgramGetAllStudyProgramsPost({studyProgramQueryParams});
}

export const addStudyProgram = (studyProgramUpdateDTO: StudyProgramUpdateDTO) => {
    return ApiFactory().apiStudyProgramAddStudyprogramPost({ studyProgramUpdateDTO });
}

export const updateStudyProgram = (id: string, studyProgramUpdateDTO: StudyProgramUpdateDTO) => { 
    return ApiFactory().apiStudyProgramUpdateStudyProgramIdPut({ id, studyProgramUpdateDTO });
}

export const deleteStudyProgram = (id: string) => {
    return ApiFactory().apiStudyProgramDeleteIdDelete({ id });
}