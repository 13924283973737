import './App.scss';
import Router from './Router/Router';
import 'antd/dist/antd.min.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import fetchIntercept from 'fetch-intercept';
import { getTokenExpire } from './utils/utilFunctions';
import { StatusCodes } from 'http-status-codes';

fetchIntercept.register({
  response: (response) => {
    const expire = getTokenExpire();
    const now = Date.now() / 1000;

    if (response.request?.headers && response.request.headers.has('authorization') && 
      response.status === StatusCodes.UNAUTHORIZED && (!expire || expire < now)) {
      localStorage.removeItem("token");
      window.location.href = '/?expired=true';
    }

    return response;
  },
  responseError: (error) => {
    const expire = getTokenExpire();
    const now = Date.now() / 1000;

    if (error.request?.headers && error.request.headers.has('authorization') && (!expire || expire < now)) {
      localStorage.removeItem("token");
      window.location.href = '/?expired=true';
    }

    return Promise.reject(error);
  }
});

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className='App'>
        <Router />
      </div>
    </QueryClientProvider>
  );
}

export default App;
