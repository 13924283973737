import Layout from '../../Containers/Layout';
import styles from './Info.module.scss';
import poli from '../../Media/poli.jpg';
import BaseMap from '../Map/BaseMap';
import { useTranslation } from 'react-i18next';

const Info = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.info}>
            <h2>{t('info.aboutUs')}</h2>
            <h3>{t('info.aboutInfo')}</h3>
          </div>
          <div className={styles.info2}>
            <img
              src={poli}
              alt=""
            />
          </div>

          <div className={styles.map}>
            <h2>{t('info.localization')}</h2>
            <BaseMap />
          </div>
        </div>
      </div>


    </Layout >
  );
};

export default Info;
