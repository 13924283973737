import { useState } from "react";
import Layout from "../../../Containers/Layout";
import styles from "./ExternalEvaluationForm.module.scss";
import { Form, DatePicker, Col, Row, Upload, Button, UploadProps, UploadFile } from "antd";
import CustomForm from "../../../CustomComponents/CustomForm";
import { theme } from "../../../theme";
import { Redirect, useParams } from "react-router-dom";
import {
NOTIFICATION_TYPES,
openNotification,
} from "../../Notifications/NotificationsUtils";
import ConfirmationModal from "../../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { RecommendationDTO, StudyCycleEnum } from "../../../Api";
import RecommendationForm from "./RecommendationForm";
import { addExternalEvaluation, getExternalEvaluation, updateExternalEvaluation } from "../../../Requests/external-evaluation-requests";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import moment, { Moment } from "moment";
import { uploadExternalEvaluation } from "../../../Requests/file-manager-requests";
import { dateFormat } from "../../../utils/constants";
import locale from 'antd/es/date-picker/locale/ro_RO';



const ExternalEvaluationForm = (props: any) => {
  const { t, i18n } = useTranslation();

  const openGetErrorNotification = (_error: any) => {
    openNotification(
      'Eroare',
      'Datele nu au putut fi aduse din server!',
      NOTIFICATION_TYPES.ERROR
    );
  };
  const [redirect, setRedirect] = useState(false);


  const [form] = Form.useForm<{
    reportDate: Moment[],
    description: string,
    recommendations: RecommendationDTO[],
    file: any,
  }>();

  const { externalEvaluationId, programId, studyCycle } = useParams<{ externalEvaluationId: string, programId: string, studyCycle: StudyCycleEnum }>();

  const { data: externalEvaluation } = useQuery(["getExternalEvaluation", externalEvaluationId], () => getExternalEvaluation(externalEvaluationId), {
    onError: (err) => {
      openGetErrorNotification(err);
    },
    onSuccess: (data) => {
      if (data) {
        form.setFieldsValue({
          reportDate: [moment(data.startDate), moment(data.reportDate)],
          recommendations: data.recommendations ?? [],
          description: data.description ?? '',
        });

      }
    },
    refetchOnWindowFocus: false
  });

  const openSaveErrorNotification = (error: any) => {
    if (error.status === 400) {
      openNotification(
        'Eroare!',
        'Datele completate nu sunt valide!',
        NOTIFICATION_TYPES.ERROR
      );
    } else {
      openNotification(
        'Eroare!',
        'Propunerea nu a putut fi salvată!',
        NOTIFICATION_TYPES.ERROR
      );
    }
  };

  const handleCancel = () => {
    setRedirect(true);
  };

  const openSuccessNotificationAndRedirect = () => {
    setRedirect(true);
    openNotification(
      'Succes!',
      'Salvarea s-a efectuat cu succes!',
      NOTIFICATION_TYPES.SUCCESS,
      6
    );
  }


  const handleSave = () => {
    const state = form.getFieldsValue();
    //TODO validate
    if (true) {
      let obj = {
        reportDate: state.reportDate[1].toDate(),
        startDate: state.reportDate[0].toDate(),
        description: state.description,
        recommendations: state.recommendations,
      };
      console.warn(obj);
      
      (externalEvaluationId ? updateExternalEvaluation(externalEvaluationId, obj)
                            : addExternalEvaluation({...obj, studyProgramId: programId})
                              .then((data) => {
                                return uploadExternalEvaluation(data.id!, fileList[0] as RcFile);
                              })
      )
      .then(openSuccessNotificationAndRedirect)
      .catch(openSaveErrorNotification);
    } else {
      openNotification(
        'Eroare!',
        'Datele introduse nu sunt valide!',
        NOTIFICATION_TYPES.ERROR
      );
    }
  };

  const { RangePicker } = DatePicker;

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const uploadProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  if (redirect) {
    return <Redirect to={{ pathname:'/' + studyCycle + "/evaluari-externe/" + programId}} />;
  } else {
    return (
      <Layout>
        <div className={styles.container}>
          <CustomForm
            form={form}
            layout={theme.layout}
            action="/domenii"
            boxshadow={"none"}
          >
            <div className={styles.firstLine}>    
              <Row gutter={2}>
                <Col xs={24} sm={24} md={24} lg={!externalEvaluationId ? 12 : 24} xl={!externalEvaluationId ? 12 : 24}>
                  <Form.Item
                    required
                    name='reportDate'
                    initialValue={[moment(), moment().add(moment.duration(30, 'days'))]}
                    label='Data evaluare:'
                    rules={[
                      {
                        required: true,
                        message: 'Data evaluării este obligatorie!',
                        type: 'array'
                      },
                    ]}
                    >
                    <RangePicker
                      locale={locale}
                      format={dateFormat}
                      style={{ width: "100%", height: "35px" }}
                    />
                  </Form.Item>
                </Col>

                {!externalEvaluationId && <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name='file'
                    label='Fisier (PDF):'
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          if (value.file.type === 'application/pdf') {
                            return Promise.resolve();
                          } else if (value.fileList.length === 0) {
                            return Promise.reject('Documentul este obligatoriu!');
                          } else {
                            return Promise.reject('Fișierul încărcat nu este PDF!');
                          }
                        }
                      },
                    ]}
                    >
                    <Upload {...uploadProps} accept=".pdf" multiple={false} maxCount={1}>
                      <Button type="primary" icon={<UploadOutlined />}>Alege fișierul</Button>
                    </Upload>
                  </Form.Item>
                </Col>}

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name='description'
                    label='Descriere:'
                    rules={[
                      {
                        required: true,
                        message: 'Descrierea este obligatorie!',
                        whitespace: true,
                      },
                    ]}
                    >
                    <textarea
                      rows={2}
                      cols={100}
                      placeholder='Completează descrierea evaluării'
                      />
                  </Form.Item>
                </Col>
                
                <Col span={24}>
                  {<RecommendationForm name="recommendations" initialValue={[{}]} isAdd={externalEvaluationId == null}/>}
                </Col>
              </Row>
            </div>
            <div className={styles.secondLine}>
              <div className={styles.firstColumn}>
                <ConfirmationModal
                  nameButton={t('internships.addInternshipForm.cancel')}
                  modalText={t('internships.cancelMessage')}
                  titleButton={''}
                  handleFunction={handleCancel}
                  iconButton={null}
                  shapeButton={''}
                  typeButton={''}
                  disabledProp={false}
                  styleProp={{ background: theme.green, color: theme.white, marginRight: '10%', marginTop: '0%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                />
                <ConfirmationModal
                  nameButton={externalEvaluationId ? t('internships.addInternshipForm.updateButton') : t('internships.addInternshipForm.saveButton')}
                  modalText={t('internships.saveMessage')}
                  titleButton={''}
                  handleFunction={handleSave}
                  iconButton={null}
                  shapeButton={''}
                  typeButton={''}
                  disabledProp={false}
                  styleProp={{ background: theme.secondColor, color: theme.white, marginRight: '10%', marginTop: '0%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                />
              </div>
            </div>
          </CustomForm>
        </div>
      </Layout>
    );
  }
};

export default ExternalEvaluationForm;
