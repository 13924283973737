/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FacultyDTO,
    FacultyDTOFromJSON,
    FacultyDTOFromJSONTyped,
    FacultyDTOToJSON,
} from './FacultyDTO';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';

/**
 * 
 * @export
 * @interface ProfessorDTO
 */
export interface ProfessorDTO {
    /**
     * 
     * @type {string}
     * @memberof ProfessorDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfessorDTO
     */
    readonly name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfessorDTO
     */
    readonly cursId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfessorDTO
     */
    readonly email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfessorDTO
     */
    readonly phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfessorDTO
     */
    readonly image?: string | null;
    /**
     * 
     * @type {Array<Role>}
     * @memberof ProfessorDTO
     */
    readonly roles?: Array<Role> | null;
    /**
     * 
     * @type {FacultyDTO}
     * @memberof ProfessorDTO
     */
    faculty?: FacultyDTO;
}

export function ProfessorDTOFromJSON(json: any): ProfessorDTO {
    return ProfessorDTOFromJSONTyped(json, false);
}

export function ProfessorDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfessorDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'cursId': !exists(json, 'cursId') ? undefined : json['cursId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] === null ? null : (json['roles'] as Array<any>).map(RoleFromJSON)),
        'faculty': !exists(json, 'faculty') ? undefined : FacultyDTOFromJSON(json['faculty']),
    };
}

export function ProfessorDTOToJSON(value?: ProfessorDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'faculty': FacultyDTOToJSON(value.faculty),
    };
}

