/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AcademicUserUpdateDTO
 */
export interface AcademicUserUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof AcademicUserUpdateDTO
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof AcademicUserUpdateDTO
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof AcademicUserUpdateDTO
     */
    cvUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUserUpdateDTO
     */
    linkedinUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUserUpdateDTO
     */
    gitUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUserUpdateDTO
     */
    siteUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUserUpdateDTO
     */
    researchField?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUserUpdateDTO
     */
    image?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AcademicUserUpdateDTO
     */
    skills?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUserUpdateDTO
     */
    departmentId?: string;
}

export function AcademicUserUpdateDTOFromJSON(json: any): AcademicUserUpdateDTO {
    return AcademicUserUpdateDTOFromJSONTyped(json, false);
}

export function AcademicUserUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcademicUserUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'phone': json['phone'],
        'cvUrl': !exists(json, 'cvUrl') ? undefined : json['cvUrl'],
        'linkedinUrl': !exists(json, 'linkedinUrl') ? undefined : json['linkedinUrl'],
        'gitUrl': !exists(json, 'gitUrl') ? undefined : json['gitUrl'],
        'siteUrl': !exists(json, 'siteUrl') ? undefined : json['siteUrl'],
        'researchField': !exists(json, 'researchField') ? undefined : json['researchField'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'skills': !exists(json, 'skills') ? undefined : json['skills'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
    };
}

export function AcademicUserUpdateDTOToJSON(value?: AcademicUserUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'phone': value.phone,
        'cvUrl': value.cvUrl,
        'linkedinUrl': value.linkedinUrl,
        'gitUrl': value.gitUrl,
        'siteUrl': value.siteUrl,
        'researchField': value.researchField,
        'image': value.image,
        'skills': value.skills,
        'departmentId': value.departmentId,
    };
}

