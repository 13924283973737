import {useState, useEffect} from 'react';
import styles from './NavBar.module.scss';
import logo from '../../Media/logo UNSTPB negru.png';
import { Link, useLocation, useHistory } from 'react-router-dom';
import CustomButton from '../../CustomComponents/CustomButton';
import { theme } from '../../theme';
import {isUserLogged } from '../../utils/utilFunctions';
import NavBarMobile from './NavBarMobile';
import { logout } from '../../utils/utilFunctions';
import { useTranslation } from "react-i18next";
import { Select, Tooltip} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getCountNotificationsNumber } from '../../Requests/notification-requests';
import HeadersLinks from "./HeadersLinks";
import {useQuery} from "react-query";

const { Option } = Select;

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
  };

  const [state, setState] = useState({
    mounted: false,
  });

  const { data: notificationsNumber } = useQuery(['getNotificationsNumber'],
    () => {
      if(isUserLogged())
        return getCountNotificationsNumber();
    });

  const handleClick = () => {
    history.push("/");
  }

  useEffect(() => {
    if (!state.mounted) {
      setState((prevState) => ({
        ...prevState,
        mounted: true,
      }));
    }
  }, [state.mounted]);

  return (
    <div className={styles.headerContainer}>
      <div className={styles.firstChild}>
        <img alt={'logo'} src={logo} onClick={handleClick} style={{height: "60px", width: "auto", margin: "0px 0 0 30%"}} />
      </div>
      <div className={styles.secondChild}>
        <HeadersLinks/>
      </div>
      <div className={styles.thirdChild}>
          {/* <Tooltip title={t('footer.frequentQuestions')}>
              <Link
                to='/intrebari-frecvente'
                style={{
                  fontWeight: location.pathname === "/intrebari-frecvente" ? "bold" : "normal",
                  color: location.pathname === "/intrebari-frecvente" ? theme.primaryColor : "black",
                  margin: '0 5% 0 0',
                  padding: '5px'
                }}
              >
                <FontAwesomeIcon icon={solid('question-circle')} style={{fontSize: theme.headerSizeIcons }}/>
              </Link>
          </Tooltip> */}

          <Tooltip title="Suport">
            <a href='https://support.upb.ro/' target={'_blank'} style={{marginRight: "10px"}}>
                <FontAwesomeIcon icon={solid('headset')} style={{fontSize: theme.headerSizeIcons }}/>
            </a>
          </Tooltip>


          {/* {isUserLogged() ?
            <Tooltip title="Notificări">
              <Badge count={notificationsNumber} size='small' style={{ margin: '0 0 0 0' }}>
                <Link 
                  to="/notificari"
                  title={t('header.notifications')}
                  style={{ 
                    fontWeight: location.pathname === "/notificari" ? "bold" : "normal", 
                    color: location.pathname === "/notificari" ? theme.primaryColor : "black",
                    margin: '0 0 0 0',
                    padding: '5px'
                  }}
                >
                  <FontAwesomeIcon icon={solid('bell')} style={{fontSize: theme.headerSizeIcons }}/>
                </Link>
              </Badge>
            </Tooltip>
          : null} */}


          {!isUserLogged() ? 
            <CustomButton
              backgroundcolor={theme.primaryColor}
              textcolor={theme.white}
              margintop={"0"}>
                <Link to='/autentificare'>
                  {t('header.login')}
                </Link>
            </CustomButton>
          : null}

          {isUserLogged() ?
            <Link to="/"
              onClick={() => logout(false)}
              style={{ 
                margin: '0 5% 0 10%',
                padding: '5px'
              }}>
              <Tooltip title={t('header.signout')}>
                <FontAwesomeIcon icon={solid('right-from-bracket')} style={{fontSize: theme.headerSizeIcons }}/>
              </Tooltip>
            </Link>
          : null }
        </div>
      <NavBarMobile />

    </div>
  );
};

export default NavBar;

