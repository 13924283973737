/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RecommendationDTO,
    RecommendationDTOFromJSON,
    RecommendationDTOFromJSONTyped,
    RecommendationDTOToJSON,
} from './RecommendationDTO';

/**
 * 
 * @export
 * @interface ExternalEvaluationReportAddDTO
 */
export interface ExternalEvaluationReportAddDTO {
    /**
     * 
     * @type {Date}
     * @memberof ExternalEvaluationReportAddDTO
     */
    reportDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ExternalEvaluationReportAddDTO
     */
    startDate?: Date;
    /**
     * 
     * @type {Array<RecommendationDTO>}
     * @memberof ExternalEvaluationReportAddDTO
     */
    recommendations?: Array<RecommendationDTO> | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalEvaluationReportAddDTO
     */
    studyProgramId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalEvaluationReportAddDTO
     */
    description?: string | null;
}

export function ExternalEvaluationReportAddDTOFromJSON(json: any): ExternalEvaluationReportAddDTO {
    return ExternalEvaluationReportAddDTOFromJSONTyped(json, false);
}

export function ExternalEvaluationReportAddDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalEvaluationReportAddDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reportDate': !exists(json, 'reportDate') ? undefined : (new Date(json['reportDate'])),
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'recommendations': !exists(json, 'recommendations') ? undefined : (json['recommendations'] === null ? null : (json['recommendations'] as Array<any>).map(RecommendationDTOFromJSON)),
        'studyProgramId': !exists(json, 'studyProgramId') ? undefined : json['studyProgramId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ExternalEvaluationReportAddDTOToJSON(value?: ExternalEvaluationReportAddDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reportDate': value.reportDate === undefined ? undefined : (value.reportDate.toISOString()),
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'recommendations': value.recommendations === undefined ? undefined : (value.recommendations === null ? null : (value.recommendations as Array<any>).map(RecommendationDTOToJSON)),
        'studyProgramId': value.studyProgramId,
        'description': value.description,
    };
}

