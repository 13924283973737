/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SenateDecisionAddDTO,
    SenateDecisionAddDTOFromJSON,
    SenateDecisionAddDTOToJSON,
    SenateDecisionDTO,
    SenateDecisionDTOFromJSON,
    SenateDecisionDTOToJSON,
    SenateDecisionDTOPagedResponse,
    SenateDecisionDTOPagedResponseFromJSON,
    SenateDecisionDTOPagedResponseToJSON,
} from '../models';

export interface ApiSenateDecisionAddSenateDecisionPostRequest {
    senateDecisionAddDTO?: SenateDecisionAddDTO;
}

export interface ApiSenateDecisionDeleteIdDeleteRequest {
    id: string;
}

export interface ApiSenateDecisionGetAllSenateDecisionsForProgramProgramIdGetRequest {
    programId: string;
    page?: number;
    pageSize?: number;
}

export interface ApiSenateDecisionGetSenateDecisionByIdIdGetRequest {
    id: string;
}

export interface ApiSenateDecisionUpdateSenateDecisionIdPutRequest {
    id: string;
    senateDecisionDTO?: SenateDecisionDTO;
}

/**
 * 
 */
export class SenateDecisionApi extends runtime.BaseAPI {

    /**
     */
    async apiSenateDecisionAddSenateDecisionPostRaw(requestParameters: ApiSenateDecisionAddSenateDecisionPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SenateDecisionDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/SenateDecision/AddSenateDecision`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SenateDecisionAddDTOToJSON(requestParameters.senateDecisionAddDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SenateDecisionDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiSenateDecisionAddSenateDecisionPost(requestParameters: ApiSenateDecisionAddSenateDecisionPostRequest = {}, initOverrides?: RequestInit): Promise<SenateDecisionDTO> {
        const response = await this.apiSenateDecisionAddSenateDecisionPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSenateDecisionDeleteIdDeleteRaw(requestParameters: ApiSenateDecisionDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSenateDecisionDeleteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/SenateDecision/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSenateDecisionDeleteIdDelete(requestParameters: ApiSenateDecisionDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiSenateDecisionDeleteIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiSenateDecisionGetAllSenateDecisionsForProgramProgramIdGetRaw(requestParameters: ApiSenateDecisionGetAllSenateDecisionsForProgramProgramIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SenateDecisionDTOPagedResponse>> {
        if (requestParameters.programId === null || requestParameters.programId === undefined) {
            throw new runtime.RequiredError('programId','Required parameter requestParameters.programId was null or undefined when calling apiSenateDecisionGetAllSenateDecisionsForProgramProgramIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/SenateDecision/GetAllSenateDecisionsForProgram/{programId}`.replace(`{${"programId"}}`, encodeURIComponent(String(requestParameters.programId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SenateDecisionDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSenateDecisionGetAllSenateDecisionsForProgramProgramIdGet(requestParameters: ApiSenateDecisionGetAllSenateDecisionsForProgramProgramIdGetRequest, initOverrides?: RequestInit): Promise<SenateDecisionDTOPagedResponse> {
        const response = await this.apiSenateDecisionGetAllSenateDecisionsForProgramProgramIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSenateDecisionGetSenateDecisionByIdIdGetRaw(requestParameters: ApiSenateDecisionGetSenateDecisionByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SenateDecisionDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSenateDecisionGetSenateDecisionByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/SenateDecision/GetSenateDecisionById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SenateDecisionDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiSenateDecisionGetSenateDecisionByIdIdGet(requestParameters: ApiSenateDecisionGetSenateDecisionByIdIdGetRequest, initOverrides?: RequestInit): Promise<SenateDecisionDTO> {
        const response = await this.apiSenateDecisionGetSenateDecisionByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSenateDecisionUpdateSenateDecisionIdPutRaw(requestParameters: ApiSenateDecisionUpdateSenateDecisionIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSenateDecisionUpdateSenateDecisionIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/SenateDecision/UpdateSenateDecision/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SenateDecisionDTOToJSON(requestParameters.senateDecisionDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSenateDecisionUpdateSenateDecisionIdPut(requestParameters: ApiSenateDecisionUpdateSenateDecisionIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiSenateDecisionUpdateSenateDecisionIdPutRaw(requestParameters, initOverrides);
    }

}
