/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccreditationTypeEnum,
    AccreditationTypeEnumFromJSON,
    AccreditationTypeEnumFromJSONTyped,
    AccreditationTypeEnumToJSON,
} from './AccreditationTypeEnum';
import {
    MasterCategoryEnum,
    MasterCategoryEnumFromJSON,
    MasterCategoryEnumFromJSONTyped,
    MasterCategoryEnumToJSON,
} from './MasterCategoryEnum';
import {
    StudyCycleEnum,
    StudyCycleEnumFromJSON,
    StudyCycleEnumFromJSONTyped,
    StudyCycleEnumToJSON,
} from './StudyCycleEnum';
import {
    StudyProgramLangEnum,
    StudyProgramLangEnumFromJSON,
    StudyProgramLangEnumFromJSONTyped,
    StudyProgramLangEnumToJSON,
} from './StudyProgramLangEnum';

/**
 * 
 * @export
 * @interface StudyProgramQueryParams
 */
export interface StudyProgramQueryParams {
    /**
     * 
     * @type {number}
     * @memberof StudyProgramQueryParams
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof StudyProgramQueryParams
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof StudyProgramQueryParams
     */
    namePattern?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof StudyProgramQueryParams
     */
    facultyIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof StudyProgramQueryParams
     */
    domainIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof StudyProgramQueryParams
     */
    universityCenterId?: string;
    /**
     * 
     * @type {StudyCycleEnum}
     * @memberof StudyProgramQueryParams
     */
    studyCycle?: StudyCycleEnum;
    /**
     * 
     * @type {StudyProgramLangEnum}
     * @memberof StudyProgramQueryParams
     */
    studyProgramLanguageOption?: StudyProgramLangEnum;
    /**
     * 
     * @type {number}
     * @memberof StudyProgramQueryParams
     */
    ectsOption?: number;
    /**
     * 
     * @type {number}
     * @memberof StudyProgramQueryParams
     */
    externalEvaluationOption?: number;
    /**
     * 
     * @type {AccreditationTypeEnum}
     * @memberof StudyProgramQueryParams
     */
    accreditationTypeOption?: AccreditationTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof StudyProgramQueryParams
     */
    capacityOption?: number;
    /**
     * 
     * @type {MasterCategoryEnum}
     * @memberof StudyProgramQueryParams
     */
    masterCategoryOption?: MasterCategoryEnum;
    /**
     * 
     * @type {number}
     * @memberof StudyProgramQueryParams
     */
    ancNoticeDateOption?: number;
    /**
     * 
     * @type {number}
     * @memberof StudyProgramQueryParams
     */
    ancExpirationDateOption?: number;
    /**
     * 
     * @type {number}
     * @memberof StudyProgramQueryParams
     */
    aracisExpirationDateOption?: number;
}

export function StudyProgramQueryParamsFromJSON(json: any): StudyProgramQueryParams {
    return StudyProgramQueryParamsFromJSONTyped(json, false);
}

export function StudyProgramQueryParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudyProgramQueryParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'namePattern': !exists(json, 'namePattern') ? undefined : json['namePattern'],
        'facultyIds': !exists(json, 'facultyIds') ? undefined : json['facultyIds'],
        'domainIds': !exists(json, 'domainIds') ? undefined : json['domainIds'],
        'universityCenterId': !exists(json, 'universityCenterId') ? undefined : json['universityCenterId'],
        'studyCycle': !exists(json, 'studyCycle') ? undefined : StudyCycleEnumFromJSON(json['studyCycle']),
        'studyProgramLanguageOption': !exists(json, 'studyProgramLanguageOption') ? undefined : StudyProgramLangEnumFromJSON(json['studyProgramLanguageOption']),
        'ectsOption': !exists(json, 'ectsOption') ? undefined : json['ectsOption'],
        'externalEvaluationOption': !exists(json, 'externalEvaluationOption') ? undefined : json['externalEvaluationOption'],
        'accreditationTypeOption': !exists(json, 'accreditationTypeOption') ? undefined : AccreditationTypeEnumFromJSON(json['accreditationTypeOption']),
        'capacityOption': !exists(json, 'capacityOption') ? undefined : json['capacityOption'],
        'masterCategoryOption': !exists(json, 'masterCategoryOption') ? undefined : MasterCategoryEnumFromJSON(json['masterCategoryOption']),
        'ancNoticeDateOption': !exists(json, 'ancNoticeDateOption') ? undefined : json['ancNoticeDateOption'],
        'ancExpirationDateOption': !exists(json, 'ancExpirationDateOption') ? undefined : json['ancExpirationDateOption'],
        'aracisExpirationDateOption': !exists(json, 'aracisExpirationDateOption') ? undefined : json['aracisExpirationDateOption'],
    };
}

export function StudyProgramQueryParamsToJSON(value?: StudyProgramQueryParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'pageSize': value.pageSize,
        'namePattern': value.namePattern,
        'facultyIds': value.facultyIds,
        'domainIds': value.domainIds,
        'universityCenterId': value.universityCenterId,
        'studyCycle': StudyCycleEnumToJSON(value.studyCycle),
        'studyProgramLanguageOption': StudyProgramLangEnumToJSON(value.studyProgramLanguageOption),
        'ectsOption': value.ectsOption,
        'externalEvaluationOption': value.externalEvaluationOption,
        'accreditationTypeOption': AccreditationTypeEnumToJSON(value.accreditationTypeOption),
        'capacityOption': value.capacityOption,
        'masterCategoryOption': MasterCategoryEnumToJSON(value.masterCategoryOption),
        'ancNoticeDateOption': value.ancNoticeDateOption,
        'ancExpirationDateOption': value.ancExpirationDateOption,
        'aracisExpirationDateOption': value.aracisExpirationDateOption,
    };
}

