/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FacultyDTO,
    FacultyDTOFromJSON,
    FacultyDTOFromJSONTyped,
    FacultyDTOToJSON,
} from './FacultyDTO';

/**
 * 
 * @export
 * @interface UniversityCenterDTO
 */
export interface UniversityCenterDTO {
    /**
     * 
     * @type {string}
     * @memberof UniversityCenterDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UniversityCenterDTO
     */
    readonly name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniversityCenterDTO
     */
    readonly shortName?: string | null;
    /**
     * 
     * @type {Array<FacultyDTO>}
     * @memberof UniversityCenterDTO
     */
    faculties?: Array<FacultyDTO> | null;
}

export function UniversityCenterDTOFromJSON(json: any): UniversityCenterDTO {
    return UniversityCenterDTOFromJSONTyped(json, false);
}

export function UniversityCenterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UniversityCenterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'faculties': !exists(json, 'faculties') ? undefined : (json['faculties'] === null ? null : (json['faculties'] as Array<any>).map(FacultyDTOFromJSON)),
    };
}

export function UniversityCenterDTOToJSON(value?: UniversityCenterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'faculties': value.faculties === undefined ? undefined : (value.faculties === null ? null : (value.faculties as Array<any>).map(FacultyDTOToJSON)),
    };
}

