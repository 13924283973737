import { useState } from 'react';
import styles from './NavBar.module.scss';
import {Link, useLocation} from 'react-router-dom';
import CustomButton from '../../CustomComponents/CustomButton';
import { theme } from '../../theme';
import {
    getUserId, isUserLogged } from '../../utils/utilFunctions';
import { logout } from '../../utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import HeadersLinks from "./HeadersLinks";
import {Drawer, Tooltip} from "antd";

const NavBarMobile = () => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const location = useLocation();

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };


    return (
        <div className={styles.navBar}>
            <Drawer
                className={styles.opened}
                placement={"top"}
                closable={true}
                onClose={onClose}
                open={visible}
                size={'large'}
                key={"top"}
            >
                <div className={styles.opened}>
                    <div className={styles.drawerHeaderContainer}>
                        <HeadersLinks/>
                        {/* <Link
                            to="/notificari"
                            style={{
                                fontWeight: location.pathname === "/notificari" ? "bold" : "normal",
                                color: theme.secondColor,
                                fontSize: "20px",
                                padding: "5px",
                            }}
                        >
                            {t('header.notifications')}
                        </Link> */}
                    </div>
                    <div className={styles.line} />
                    <div className={styles.openLink}>
                        {!isUserLogged() ? (
                            <CustomButton
                                backgroundcolor={theme.primaryColor}
                                textcolor={theme.white}
                                marginleft={"auto"}
                                marginright={"auto"}
                                marginbottom={"5%"}
                            >
                            <Link to='/autentificare'>
                                {t('header.login')}
                            </Link>
                            </CustomButton>) : null}
                    </div>
                    <div className={styles.openLink}>
                        {isUserLogged() ?
                        (<Tooltip title={t('header.signout')}>
                            <CustomButton
                                backgroundcolor={theme.secondColor}
                                textcolor={theme.white}
                                onClick={() => logout(true)}
                                marginleft={"auto"}
                                marginright={"auto"}
                            >
                                <Link to="/">{t('header.logout')}</Link>
                            </CustomButton>
                        </Tooltip>) : null}
                    </div>
                </div>
            </Drawer>
            <span style={{ fontSize: "17px", cursor: "pointer" }} onClick={showDrawer}>
                <h1>&#9776;</h1>
            </span>
        </div>
        );
}

export default NavBarMobile;