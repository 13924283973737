import { DomainApi, DomainDTO, DomainUpdateDTO, StudyCycleEnum } from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new DomainApi(getAuthConfiguration());

export const getDomain = (id: string) => {
    if (!id) {
        return {} as DomainDTO;
    }
    return ApiFactory().apiDomainGetDomainByIdIdGet({ id });
}

export const getDomains = (namePattern?: string, facultyIds?: string[], studyCycle: StudyCycleEnum = StudyCycleEnum.All, page: number = 1, pageSize: number = 10) => {
    return ApiFactory().apiDomainGetAllDomainsGet({ page, pageSize, namePattern, facultyIds, studyCycle });
}

export const addDomain = (domainUpdateDTO: DomainUpdateDTO) => {
    return ApiFactory().apiDomainAddDomainPost({ domainUpdateDTO });
}

export const updateDomain = (id: string, domainUpdateDTO: DomainUpdateDTO) => { 
    return ApiFactory().apiDomainUpdateDomainIdPut({ id, domainUpdateDTO });
}

export const deleteDomain = (id: string) => {
    return ApiFactory().apiDomainDeleteIdDelete({ id });
}