/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccreditationTypeEnum,
    AccreditationTypeEnumFromJSON,
    AccreditationTypeEnumFromJSONTyped,
    AccreditationTypeEnumToJSON,
} from './AccreditationTypeEnum';
import {
    MasterCategoryEnum,
    MasterCategoryEnumFromJSON,
    MasterCategoryEnumFromJSONTyped,
    MasterCategoryEnumToJSON,
} from './MasterCategoryEnum';
import {
    StudyCycleEnum,
    StudyCycleEnumFromJSON,
    StudyCycleEnumFromJSONTyped,
    StudyCycleEnumToJSON,
} from './StudyCycleEnum';
import {
    StudyProgramLangEnum,
    StudyProgramLangEnumFromJSON,
    StudyProgramLangEnumFromJSONTyped,
    StudyProgramLangEnumToJSON,
} from './StudyProgramLangEnum';

/**
 * 
 * @export
 * @interface StudyProgramUpdateDTO
 */
export interface StudyProgramUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof StudyProgramUpdateDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StudyProgramUpdateDTO
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StudyProgramUpdateDTO
     */
    numberECTS?: number;
    /**
     * 
     * @type {AccreditationTypeEnum}
     * @memberof StudyProgramUpdateDTO
     */
    accreditationType?: AccreditationTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof StudyProgramUpdateDTO
     */
    capacity?: number;
    /**
     * 
     * @type {string}
     * @memberof StudyProgramUpdateDTO
     */
    educaionalDomainISCED?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyProgramUpdateDTO
     */
    codCOR?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyProgramUpdateDTO
     */
    ocupationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyProgramUpdateDTO
     */
    ancNoticeNumber?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StudyProgramUpdateDTO
     */
    ancNoticeDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StudyProgramUpdateDTO
     */
    ancExpireDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StudyProgramUpdateDTO
     */
    aracisNoticeDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StudyProgramUpdateDTO
     */
    aracisExpireDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof StudyProgramUpdateDTO
     */
    externalEvaluationHistory?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyProgramUpdateDTO
     */
    ancFilePath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyProgramUpdateDTO
     */
    aracisRequestPath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyProgramUpdateDTO
     */
    facultyId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudyProgramUpdateDTO
     */
    domainId?: string;
    /**
     * 
     * @type {StudyCycleEnum}
     * @memberof StudyProgramUpdateDTO
     */
    studyCycle?: StudyCycleEnum;
    /**
     * 
     * @type {string}
     * @memberof StudyProgramUpdateDTO
     */
    programSupervisor?: string | null;
    /**
     * 
     * @type {StudyProgramLangEnum}
     * @memberof StudyProgramUpdateDTO
     */
    language?: StudyProgramLangEnum;
    /**
     * 
     * @type {MasterCategoryEnum}
     * @memberof StudyProgramUpdateDTO
     */
    masterCategory?: MasterCategoryEnum;
}

export function StudyProgramUpdateDTOFromJSON(json: any): StudyProgramUpdateDTO {
    return StudyProgramUpdateDTOFromJSONTyped(json, false);
}

export function StudyProgramUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudyProgramUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'numberECTS': !exists(json, 'numberECTS') ? undefined : json['numberECTS'],
        'accreditationType': !exists(json, 'accreditationType') ? undefined : AccreditationTypeEnumFromJSON(json['accreditationType']),
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'educaionalDomainISCED': !exists(json, 'educaionalDomainISCED') ? undefined : json['educaionalDomainISCED'],
        'codCOR': !exists(json, 'codCOR') ? undefined : json['codCOR'],
        'ocupationName': !exists(json, 'ocupationName') ? undefined : json['ocupationName'],
        'ancNoticeNumber': !exists(json, 'ancNoticeNumber') ? undefined : json['ancNoticeNumber'],
        'ancNoticeDate': !exists(json, 'ancNoticeDate') ? undefined : (new Date(json['ancNoticeDate'])),
        'ancExpireDate': !exists(json, 'ancExpireDate') ? undefined : (new Date(json['ancExpireDate'])),
        'aracisNoticeDate': !exists(json, 'aracisNoticeDate') ? undefined : (new Date(json['aracisNoticeDate'])),
        'aracisExpireDate': !exists(json, 'aracisExpireDate') ? undefined : (new Date(json['aracisExpireDate'])),
        'externalEvaluationHistory': !exists(json, 'externalEvaluationHistory') ? undefined : json['externalEvaluationHistory'],
        'ancFilePath': !exists(json, 'ancFilePath') ? undefined : json['ancFilePath'],
        'aracisRequestPath': !exists(json, 'aracisRequestPath') ? undefined : json['aracisRequestPath'],
        'facultyId': !exists(json, 'facultyId') ? undefined : json['facultyId'],
        'domainId': !exists(json, 'domainId') ? undefined : json['domainId'],
        'studyCycle': !exists(json, 'studyCycle') ? undefined : StudyCycleEnumFromJSON(json['studyCycle']),
        'programSupervisor': !exists(json, 'programSupervisor') ? undefined : json['programSupervisor'],
        'language': !exists(json, 'language') ? undefined : StudyProgramLangEnumFromJSON(json['language']),
        'masterCategory': !exists(json, 'masterCategory') ? undefined : MasterCategoryEnumFromJSON(json['masterCategory']),
    };
}

export function StudyProgramUpdateDTOToJSON(value?: StudyProgramUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'numberECTS': value.numberECTS,
        'accreditationType': AccreditationTypeEnumToJSON(value.accreditationType),
        'capacity': value.capacity,
        'educaionalDomainISCED': value.educaionalDomainISCED,
        'codCOR': value.codCOR,
        'ocupationName': value.ocupationName,
        'ancNoticeNumber': value.ancNoticeNumber,
        'ancNoticeDate': value.ancNoticeDate === undefined ? undefined : (value.ancNoticeDate.toISOString()),
        'ancExpireDate': value.ancExpireDate === undefined ? undefined : (value.ancExpireDate.toISOString()),
        'aracisNoticeDate': value.aracisNoticeDate === undefined ? undefined : (value.aracisNoticeDate.toISOString()),
        'aracisExpireDate': value.aracisExpireDate === undefined ? undefined : (value.aracisExpireDate.toISOString()),
        'externalEvaluationHistory': value.externalEvaluationHistory,
        'ancFilePath': value.ancFilePath,
        'aracisRequestPath': value.aracisRequestPath,
        'facultyId': value.facultyId,
        'domainId': value.domainId,
        'studyCycle': StudyCycleEnumToJSON(value.studyCycle),
        'programSupervisor': value.programSupervisor,
        'language': StudyProgramLangEnumToJSON(value.language),
        'masterCategory': MasterCategoryEnumToJSON(value.masterCategory),
    };
}

