/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AcademicUserUpdateDTO,
    AcademicUserUpdateDTOFromJSON,
    AcademicUserUpdateDTOToJSON,
    AcademicUserUpdateRoleDTO,
    AcademicUserUpdateRoleDTOFromJSON,
    AcademicUserUpdateRoleDTOToJSON,
    AdminDTO,
    AdminDTOFromJSON,
    AdminDTOToJSON,
    ProfessorDTO,
    ProfessorDTOFromJSON,
    ProfessorDTOToJSON,
    Role,
    RoleFromJSON,
    RoleToJSON,
    SimpleViewAcademicUserDTOPagedResponse,
    SimpleViewAcademicUserDTOPagedResponseFromJSON,
    SimpleViewAcademicUserDTOPagedResponseToJSON,
} from '../models';

export interface ApiAcademicUserGetAdminByIdIdGetRequest {
    id: string;
}

export interface ApiAcademicUserGetAllAcademicUsersGetRequest {
    namePattern?: string;
    faculties?: Array<string>;
    departments?: Array<string>;
    page?: number;
    pageSize?: number;
}

export interface ApiAcademicUserUpdateAcademicUserRoleIdPutRequest {
    id: string;
    academicUserUpdateRoleDTO?: AcademicUserUpdateRoleDTO;
}

export interface ApiAcademicUserUpdateAdminIdPutRequest {
    id: string;
    academicUserUpdateDTO?: AcademicUserUpdateDTO;
}

/**
 * 
 */
export class AcademicUserApi extends runtime.BaseAPI {

    /**
     */
    async apiAcademicUserGetAdminByIdIdGetRaw(requestParameters: ApiAcademicUserGetAdminByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdminDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserGetAdminByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetAdminById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetAdminByIdIdGet(requestParameters: ApiAcademicUserGetAdminByIdIdGetRequest, initOverrides?: RequestInit): Promise<AdminDTO> {
        const response = await this.apiAcademicUserGetAdminByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetAllAcademicUsersGetRaw(requestParameters: ApiAcademicUserGetAllAcademicUsersGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SimpleViewAcademicUserDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.faculties) {
            queryParameters['Faculties'] = requestParameters.faculties;
        }

        if (requestParameters.departments) {
            queryParameters['Departments'] = requestParameters.departments;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetAllAcademicUsers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleViewAcademicUserDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetAllAcademicUsersGet(requestParameters: ApiAcademicUserGetAllAcademicUsersGetRequest = {}, initOverrides?: RequestInit): Promise<SimpleViewAcademicUserDTOPagedResponse> {
        const response = await this.apiAcademicUserGetAllAcademicUsersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetRolesForUserGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Role>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetRolesForUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleFromJSON));
    }

    /**
     */
    async apiAcademicUserGetRolesForUserGet(initOverrides?: RequestInit): Promise<Array<Role>> {
        const response = await this.apiAcademicUserGetRolesForUserGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserUpdateAcademicUserRoleIdPutRaw(requestParameters: ApiAcademicUserUpdateAcademicUserRoleIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfessorDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserUpdateAcademicUserRoleIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/UpdateAcademicUserRole/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AcademicUserUpdateRoleDTOToJSON(requestParameters.academicUserUpdateRoleDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfessorDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserUpdateAcademicUserRoleIdPut(requestParameters: ApiAcademicUserUpdateAcademicUserRoleIdPutRequest, initOverrides?: RequestInit): Promise<ProfessorDTO> {
        const response = await this.apiAcademicUserUpdateAcademicUserRoleIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserUpdateAdminIdPutRaw(requestParameters: ApiAcademicUserUpdateAdminIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdminDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserUpdateAdminIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/UpdateAdmin/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AcademicUserUpdateDTOToJSON(requestParameters.academicUserUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserUpdateAdminIdPut(requestParameters: ApiAcademicUserUpdateAdminIdPutRequest, initOverrides?: RequestInit): Promise<AdminDTO> {
        const response = await this.apiAcademicUserUpdateAdminIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
