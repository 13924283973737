import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notification } from 'antd';
import { theme } from '../../theme';

export const NOTIFICATION_TYPES = {
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    INFO: "INFO"

}
export const openNotification = (message: string, description: string, type: any, duration = 3) => {
    if (type === NOTIFICATION_TYPES.ERROR) {
        notification.open({
            message: message,
            duration: 3,
            description: description,
            style: { fontFamily: theme.font },
            icon: <FontAwesomeIcon icon={regular('face-meh')} style={{ color: 'rgba(255, 0, 0, 0.7)' }} />,
        });
    }
    if (type === NOTIFICATION_TYPES.SUCCESS) {
        notification.open({
            message: message,
            duration: 2,
            description: description,
            style: { fontFamily: theme.font },
            icon: <FontAwesomeIcon icon={regular('face-smile')} style={{ color: theme.primaryColor }} />,
        });
    }
    if (type === NOTIFICATION_TYPES.INFO) {
        notification.open({
            message: message,
            duration: duration,
            description: description,
            style: { fontFamily: theme.font },
            icon: <FontAwesomeIcon icon={regular('face-smile')} style={{ color: theme.primaryColor }} />,
        });
    }
};