import {  useState } from "react";
import styles from "./Domains.module.scss";
import { Button, MenuProps, Modal, Progress, Select, Table, TablePaginationConfig, Tooltip, Typography } from "antd";
import Search from 'antd/lib/input/Search';
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import Layout from "../../Containers/Layout";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlusOutlined } from "@ant-design/icons";
import { Link, useHistory  } from "react-router-dom";
import { theme } from "../../theme";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useIsAdmin } from "../../utils/utilFunctions";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import { deleteDomain, getDomains } from "../../Requests/domain-requests";
import { DomainDTO, SimpleStudyCycleEnum, StudyCycleEnum } from "../../Api";
const { Option } = Select;

interface PageResponse<T> {
    data?: T[] | null,
    totalCount?: number,
    page?: number,
    pageSize?: number
}

export type PageCallBack<T> = (currentPage: number, pageSize: number) => Promise<PageResponse<T> | null | undefined>;
export type ColumnType<T> = { title: string, key: any, dataIndex?: string, width?: any, render?: (text: string, record: T, index: number) => any };

const Domains = () => {
    const { t } = useTranslation();
    const [searchTermName, setSearchTermName] = useState('');
    const [searchTerStudyCycle, setSearchTerStudyCycle] = useState(StudyCycleEnum.All);
    const [facultiesFilter, setFacultiesFilter] = useState<string[]>();
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrent] = useState(1);
    const history = useHistory();
    const isAdmin = useIsAdmin();

    const getActions = (record: any): ItemType[] | undefined => {
        let actions : MenuProps['items'] = [
            {
                label: 'Actiune test',
                key: 1, //TODO add action enum
                icon:
                <Tooltip title='Tooltip actiune test'>
                    <FontAwesomeIcon icon={solid('download')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>,
            }
        ];
        return actions;
    }
    const [modalPropsDelete, setModalPropsDelete] = useState({
        isModalVisible: false,
        title: '',
        id: '',
    });

    const [modalProps, setModalProps] = useState<{ isModalVisible: boolean, title: string, data: string[]}>({
        isModalVisible: false,
        title: '',
        data: []
    });

    const handleShow = (id: string, uploadHandler: any, fileExtension: string = 'zip') => {
        setModalProps(modalProps => ({...modalProps, isModalVisible: true, id, uploadHandler, fileExtension}));
    }

    const handleClose = () => {
        setModalProps(modalProps => ({...modalProps, isModalVisible: false}));
    }

    const handleOk = () => {
        setModalProps(modalProps => ({...modalProps, isModalVisible: false}));

    }

    const handleShowDelete = (id: string) => {
        setModalPropsDelete(modalProps => ({...modalProps, isModalVisible: true, id}));
    }

    const handleShowFaculties = (record: DomainDTO) => {
        setModalProps(modalProps => ({...modalProps, isModalVisible: true, title: "Facultăți asociate domeniului",
            data: (record.faculties!.length > 0 ? record.faculties!.map((faculty: any) => faculty.name) : ["Nici o facultate asociată"])}));
    }
    const handleShowPrograms = (record: DomainDTO) => {
        setModalProps(modalProps => ({...modalProps, isModalVisible: true, title: "Programe asociate domeniului",
            data: record.studyPrograms!.length > 0 ? record.studyPrograms!.map((program: any) => program.name) : ["Nici un program asociat"]}));
    }

    const handleCloseDelete = () => {
        setModalPropsDelete(modalProps => ({...modalProps, isModalVisible: false}));
    }

    const handleOkDelete = () => {
        setModalPropsDelete(modalProps => ({...modalProps, isModalVisible: false}));
        deleteDomain(modalPropsDelete.id)
            .then(() => {
                deleteSucceded();
                invalidateQuery();
            })
            .catch(deleteFailed);

    }
  
    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrent(() => pagination.current ?? 1);
        setPageSize(() => pagination.pageSize ?? 10);
    };

    const deleteSucceded = () => {
        openNotification(
            'Succes',
            'Ștergerea s-a realizat cu succes!',
            NOTIFICATION_TYPES.SUCCESS
        );
    };

    const deleteFailed = (_err: any) => {
        openNotification(
            'Eroare',
            'Ștergerea nu s-a realizat cu succes!',
            NOTIFICATION_TYPES.ERROR
        );
    };

    const queryClient = useQueryClient();

    const invalidateQuery = () => { queryClient.invalidateQueries('getDomains') };

    const openFetchError = (_error: any) => {
        openNotification(
            'Eroare!',
            'Datele nu au putut fi aduse din server!',
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data, isLoading } = useQuery(['getDomains', searchTermName, facultiesFilter, pageSize, currentPage, searchTerStudyCycle],
        () => {
            return getDomains(searchTermName, facultiesFilter, searchTerStudyCycle, currentPage, pageSize);
        },
        {
            onError: openFetchError,
        }
    );

    const FilterByNameInput = (
        <Search placeholder={t('account.name')} allowClear onSearch={setSearchTermName} style={{ width: '100%' }} />
    );

    const FilterByStudyCycle = (
        <Select defaultValue={StudyCycleEnum.All} style={{ width: "100px" }} onChange={setSearchTerStudyCycle}>
            <Option key={StudyCycleEnum.All}>{"Toate"}</Option>
            <Option key={StudyCycleEnum.Bachelor}>{"Licență"}</Option>
            <Option key={StudyCycleEnum.Master}>{"Masterat"}</Option>
        </Select>
    );

    const columns = [
        {
            title: FilterByNameInput,
            dataIndex: 'completeName',
            render: (text: string, record: any, index: number) => {
                return record.fundamentalName + " - " + record.name;
            }
        },
        {
            title: FilterByStudyCycle,
            dataIndex: 'studyCycle',
            width: '150px',
            render: (text: string, record: any, index: number) => {
                return record.studyCycle === SimpleStudyCycleEnum.Bachelor ? "Licență" : "Masterat";
            }
        },
        {
            title: 'Programe asociate',
            width: '100px',
            render: (text: string, record: any, index: number) =>
                <div>
                    <Tooltip title='Vizualizare'>
                        <FontAwesomeIcon icon={solid('eye')} style={{ marginLeft: '20px', cursor: 'pointer', height: "1rem", padding: "6px 0px" }}
                        onClick={() => handleShowPrograms(record)}/>
                    </Tooltip>
                </div>
            
        },
        {
            title: 'Facultăți asociate',
            width: '100px',
            render: (text: string, record: any, index: number) =>
                <div>
                    <Tooltip title='Vizualizare'>
                        <FontAwesomeIcon icon={solid('eye')} style={{ marginLeft: '20px', cursor: 'pointer', height: "1rem", padding: "6px 0px" }}
                        onClick={() => handleShowFaculties(record)}/>
                    </Tooltip>
                </div>
            
        },
        {
            title: 'Acțiuni',
            width: '100px',
            render: (text: string, record: any, index: number) => 
                <div>
                    <Link to={"/editare-domeniu/" + record.id}><Tooltip title='Editare'>
                        <FontAwesomeIcon icon={solid('edit')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                    </Tooltip></Link>
                    <Tooltip title='Ștergere'>
                        <FontAwesomeIcon icon={solid('trash')} style={{ marginLeft: '20px', cursor: 'pointer', height: "1rem", padding: "6px 0px" }}
                        onClick={() => handleShowDelete(record.id)}/>
                    </Tooltip>
                </div>
        }
    ];


    const reportColumns: ColumnType<any>[] = [
        {
            title: 'Denumire',
            key: 'name',
        }
    ];

    const [progress, setProgress] = useState(0);
    const [downloading, setDownloading] = useState(false);
    const [downloadError, setDownloadError] = useState(false);

    const downloadCsv = async (columns: { header: string, key: string }[], objs?: any[] | null) => {
        // if (objs === undefined || objs === null) {
        //     return;
        // }
    
        // objs.forEach(e => {
        //     e.initiatorName = e.initiator.name;
        //     e.programType = ProgramTypeEnumConverter(e.programType)
        //     e.studyDomain = StudyDomainEnumConverter(e.studyDomain)
        //     e.proposalStatus = ProposalStatusEnumConverter(e.proposalStatus)
        //     e.credits = e.teachingPlan.map((s: { credits: any; }) => s.credits).reduce((partialSum: number, a: number) => partialSum + a, 0);
        // });

        // const workbook = new excel.Workbook();
        // const sheet = workbook.addWorksheet('Report');
        // sheet.columns = columns;
        // sheet.addRows(objs);
    
        // const csv = await workbook.csv.writeBuffer({
        //     encoding: 'utf-16', formatterOptions: {
        //         delimiter: ',',
        //         quote: "\""
        //     }
        // });
    
        // saveAs(
        //     new Blob([csv], {
        //         type: 'text/csv'
        //     }),
        //     'Report.csv'
        // );
    };
    
    const openErrorNotification = (error: string, message: string) => {
        openNotification(
            error,
            message,
            NOTIFICATION_TYPES.ERROR
        );
    }
    
    async function downloadAll<T>(getCall: PageCallBack<T>, setProgress: (progress: number) => void, onError: () => void) {
        let data = new Array<T>();
        let hasData = true;
        let pageIndex = 1;
        setProgress(0);
    
        do {
            const page = await getCall(pageIndex, 100).catch(onError);
    
            ++pageIndex;
    
            if (page?.data === undefined || page?.data === null) {
                onError();
    
                return;
            }
    
            data = data.concat(page.data);
    
            if ((pageIndex - 1) * 100 >= (page.totalCount ?? 0)) {
                hasData = false;
            }
    
            setProgress(hasData ? (Math.round((pageIndex - 1) * 100 / (page.totalCount ?? 1) * 100)) : 100)
        } while (hasData);
    
        return data;
    }

    const downloadReport = async () => {
        // setDownloading(true);

        // return downloadCsv(reportColumns.map(e => { return { header: e.title, key: e.key?.toString() ?? '' } }),
        //     await downloadAll((page, size) => getDomains(searchTermName, searchTermDomain, searchTermFaculty, searchTermType, page, size), (value) => {
        //         setDownloadError(false);
        //         setProgress(value)
        //     }, () => {
        //         setDownloadError(true);
        //         openErrorNotification("Eroare!", "Raportul nu a putut fi descărcat cu succes!")
        //     }));
    }

    return <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
        <Layout>
            <Modal
                open={modalPropsDelete.isModalVisible}
                onOk={handleOkDelete}
                onCancel={handleCloseDelete}
                title="Ștergere"
                width={"400px"}
                okText={t('account.confirm')}
                cancelText={t('account.cancel')}
                cancelButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white, borderRadius: "10px" } }}
                okButtonProps={{ style: { background: theme.secondColor, border: theme.secondColor, borderRadius: "10px" } }}
                >
                    <div>
                        <Typography>Sigur doriti stergerea domeniului?</Typography>
                    </div>
            </Modal>
            <Modal
                open={modalProps.isModalVisible}
                onOk={handleOk}
                onCancel={handleClose}
                title={modalProps.title}
                width={"500px"}
                okText={t('account.confirm')}
                cancelText={t('account.cancel')}
                cancelButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white, borderRadius: "10px" } }}
                okButtonProps={{ style: { background: theme.secondColor, border: theme.secondColor, borderRadius: "10px" } }}
                >
                    <div>
                        {modalProps.data.map(name => <Typography key={name}>- {name}</Typography>)}
                    </div>
            </Modal>
            {downloading && <div onClick={() => setDownloading(false)} style={{cursor: "pointer"}}>
                <Progress percent={progress} status={downloadError ? "exception" : undefined} />
            </div>}
            <Table
                className={styles.usersTable}
                dataSource={data?.data || []}
                scroll={{ y: 'calc(100vh - 330px)'  }}
                sticky={true}
                pagination={{
                    total: data?.totalCount,
                    current: currentPage,
                    pageSize: data?.pageSize,
                    pageSizeOptions: ["10", "20", "50"],
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    showTotal: (total: number, range: [number, number]) => {
                        return <div>
                                {/* {isAdmin && <Tooltip title="Descărcare listă domenii">
                                    <Button type="primary" icon={<DownloadOutlined />} style={{marginRight: 10}} onClick={downloadReport}>
                                        Descărcare listă domenii
                                    </Button>
                                </Tooltip>} */}
                                <Tooltip title="Adaugă domeniu">
                                    <Link to='/adaugare-domeniu'>
                                        <Button type="primary" icon={<PlusOutlined />}>
                                            Adaugă domeniu
                                        </Button>
                                    </Link>
                                </Tooltip>
                            </div>
                    },
                    hideOnSinglePage: false,
                    locale: { items_per_page: t('configuration.pagination')},
                    position: ['topRight']
                }}
                locale={{
                    emptyText: (
                        <span>
                            <h3>Nu există domenii adăugate! Apasă butonul de mai jos pentru a adăuga un domeniu nou!</h3>
                            <Tooltip title="Adaugă domeniu">
                                <Link to='/adaugare-domeniu'>
                                    <Button type="primary" icon={<PlusOutlined />}>
                                        Adaugă domeniu
                                    </Button>
                                </Link>
                            </Tooltip>
                        </span>
                      )
                }}
                columns={columns}
                rowKey={record => record.id!}
                loading={isLoading}
                onChange={handleTableChange}
            />
        </Layout>
    </div>
}

export default Domains;
