/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Faculty,
    FacultyFromJSON,
    FacultyFromJSONTyped,
    FacultyToJSON,
} from './Faculty';

/**
 * 
 * @export
 * @interface UniversityCenter
 */
export interface UniversityCenter {
    /**
     * 
     * @type {string}
     * @memberof UniversityCenter
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof UniversityCenter
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UniversityCenter
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof UniversityCenter
     */
    name?: string | null;
    /**
     * 
     * @type {Array<Faculty>}
     * @memberof UniversityCenter
     */
    faculties?: Array<Faculty> | null;
}

export function UniversityCenterFromJSON(json: any): UniversityCenter {
    return UniversityCenterFromJSONTyped(json, false);
}

export function UniversityCenterFromJSONTyped(json: any, ignoreDiscriminator: boolean): UniversityCenter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'faculties': !exists(json, 'faculties') ? undefined : (json['faculties'] === null ? null : (json['faculties'] as Array<any>).map(FacultyFromJSON)),
    };
}

export function UniversityCenterToJSON(value?: UniversityCenter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'name': value.name,
        'faculties': value.faculties === undefined ? undefined : (value.faculties === null ? null : (value.faculties as Array<any>).map(FacultyToJSON)),
    };
}

