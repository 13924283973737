/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccreditationTypeEnum,
    AccreditationTypeEnumFromJSON,
    AccreditationTypeEnumFromJSONTyped,
    AccreditationTypeEnumToJSON,
} from './AccreditationTypeEnum';
import {
    MasterCategoryEnum,
    MasterCategoryEnumFromJSON,
    MasterCategoryEnumFromJSONTyped,
    MasterCategoryEnumToJSON,
} from './MasterCategoryEnum';
import {
    StudyProgramLangEnum,
    StudyProgramLangEnumFromJSON,
    StudyProgramLangEnumFromJSONTyped,
    StudyProgramLangEnumToJSON,
} from './StudyProgramLangEnum';

/**
 * 
 * @export
 * @interface FilterOptionsDTO
 */
export interface FilterOptionsDTO {
    /**
     * 
     * @type {Array<StudyProgramLangEnum>}
     * @memberof FilterOptionsDTO
     */
    studyProgramLanguageOptions?: Array<StudyProgramLangEnum> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FilterOptionsDTO
     */
    ectsOptions?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FilterOptionsDTO
     */
    externalEvaluationOptions?: Array<number> | null;
    /**
     * 
     * @type {Array<AccreditationTypeEnum>}
     * @memberof FilterOptionsDTO
     */
    accreditationTypeOptions?: Array<AccreditationTypeEnum> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FilterOptionsDTO
     */
    capacityOptions?: Array<number> | null;
    /**
     * 
     * @type {Array<MasterCategoryEnum>}
     * @memberof FilterOptionsDTO
     */
    masterCategoryOptions?: Array<MasterCategoryEnum> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FilterOptionsDTO
     */
    ancNoticeDateOptions?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FilterOptionsDTO
     */
    ancExpirationDateOptions?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FilterOptionsDTO
     */
    aracisExpirationDateOptions?: Array<number> | null;
}

export function FilterOptionsDTOFromJSON(json: any): FilterOptionsDTO {
    return FilterOptionsDTOFromJSONTyped(json, false);
}

export function FilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterOptionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'studyProgramLanguageOptions': !exists(json, 'studyProgramLanguageOptions') ? undefined : (json['studyProgramLanguageOptions'] === null ? null : (json['studyProgramLanguageOptions'] as Array<any>).map(StudyProgramLangEnumFromJSON)),
        'ectsOptions': !exists(json, 'ectsOptions') ? undefined : json['ectsOptions'],
        'externalEvaluationOptions': !exists(json, 'externalEvaluationOptions') ? undefined : json['externalEvaluationOptions'],
        'accreditationTypeOptions': !exists(json, 'accreditationTypeOptions') ? undefined : (json['accreditationTypeOptions'] === null ? null : (json['accreditationTypeOptions'] as Array<any>).map(AccreditationTypeEnumFromJSON)),
        'capacityOptions': !exists(json, 'capacityOptions') ? undefined : json['capacityOptions'],
        'masterCategoryOptions': !exists(json, 'masterCategoryOptions') ? undefined : (json['masterCategoryOptions'] === null ? null : (json['masterCategoryOptions'] as Array<any>).map(MasterCategoryEnumFromJSON)),
        'ancNoticeDateOptions': !exists(json, 'ancNoticeDateOptions') ? undefined : json['ancNoticeDateOptions'],
        'ancExpirationDateOptions': !exists(json, 'ancExpirationDateOptions') ? undefined : json['ancExpirationDateOptions'],
        'aracisExpirationDateOptions': !exists(json, 'aracisExpirationDateOptions') ? undefined : json['aracisExpirationDateOptions'],
    };
}

export function FilterOptionsDTOToJSON(value?: FilterOptionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'studyProgramLanguageOptions': value.studyProgramLanguageOptions === undefined ? undefined : (value.studyProgramLanguageOptions === null ? null : (value.studyProgramLanguageOptions as Array<any>).map(StudyProgramLangEnumToJSON)),
        'ectsOptions': value.ectsOptions,
        'externalEvaluationOptions': value.externalEvaluationOptions,
        'accreditationTypeOptions': value.accreditationTypeOptions === undefined ? undefined : (value.accreditationTypeOptions === null ? null : (value.accreditationTypeOptions as Array<any>).map(AccreditationTypeEnumToJSON)),
        'capacityOptions': value.capacityOptions,
        'masterCategoryOptions': value.masterCategoryOptions === undefined ? undefined : (value.masterCategoryOptions === null ? null : (value.masterCategoryOptions as Array<any>).map(MasterCategoryEnumToJSON)),
        'ancNoticeDateOptions': value.ancNoticeDateOptions,
        'ancExpirationDateOptions': value.ancExpirationDateOptions,
        'aracisExpirationDateOptions': value.aracisExpirationDateOptions,
    };
}

