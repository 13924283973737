import { useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "./BaseMap.scss";

const BaseMap = () => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiYW5hbWFyaWFwYWR1cmFydTM0M2M1IiwiYSI6ImNrcW5jNzRmcTAycGUycnFobnl0ZW96cGsifQ.R18YUCdGnEEV7FNSgfBpig';
    // add markers to map
    useEffect(() => {
        const map = new mapboxgl.Map({
            container: "mapContainer",
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [26.049288685508852, 44.43866137072266],
            zoom: 12
        });
        const el = document.createElement('div');
        el.id = 'marker';

        // create the marker
        new mapboxgl.Marker(el)
            .setLngLat([26.0492889855085, 44.44166937072266])
            .setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML("<h4>Universitatea POLITEHNICA </h4> <p> București 060042</p>")) // sets a popup on this marker
            .addTo(map);
    }, []);

    return <div id="mapContainer">
    </div>;
};

export default BaseMap;
