/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExternalEvaluationReportAddDTO,
    ExternalEvaluationReportAddDTOFromJSON,
    ExternalEvaluationReportAddDTOToJSON,
    ExternalEvaluationReportDTO,
    ExternalEvaluationReportDTOFromJSON,
    ExternalEvaluationReportDTOToJSON,
    ExternalEvaluationReportDTOPagedResponse,
    ExternalEvaluationReportDTOPagedResponseFromJSON,
    ExternalEvaluationReportDTOPagedResponseToJSON,
} from '../models';

export interface ApiExternalEvaluationAddExernalEvaluationPostRequest {
    externalEvaluationReportAddDTO?: ExternalEvaluationReportAddDTO;
}

export interface ApiExternalEvaluationDeleteIdDeleteRequest {
    id: string;
}

export interface ApiExternalEvaluationGetAllExernalEvaluationsForProgramProgramIdGetRequest {
    programId: string;
    page?: number;
    pageSize?: number;
}

export interface ApiExternalEvaluationGetExernalEvaluationByIdIdGetRequest {
    id: string;
}

export interface ApiExternalEvaluationUpdateExernalEvaluationIdPutRequest {
    id: string;
    externalEvaluationReportDTO?: ExternalEvaluationReportDTO;
}

/**
 * 
 */
export class ExternalEvaluationApi extends runtime.BaseAPI {

    /**
     */
    async apiExternalEvaluationAddExernalEvaluationPostRaw(requestParameters: ApiExternalEvaluationAddExernalEvaluationPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExternalEvaluationReportDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ExternalEvaluation/AddExernalEvaluation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalEvaluationReportAddDTOToJSON(requestParameters.externalEvaluationReportAddDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalEvaluationReportDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiExternalEvaluationAddExernalEvaluationPost(requestParameters: ApiExternalEvaluationAddExernalEvaluationPostRequest = {}, initOverrides?: RequestInit): Promise<ExternalEvaluationReportDTO> {
        const response = await this.apiExternalEvaluationAddExernalEvaluationPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiExternalEvaluationDeleteIdDeleteRaw(requestParameters: ApiExternalEvaluationDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiExternalEvaluationDeleteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ExternalEvaluation/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiExternalEvaluationDeleteIdDelete(requestParameters: ApiExternalEvaluationDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiExternalEvaluationDeleteIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiExternalEvaluationGetAllExernalEvaluationsForProgramProgramIdGetRaw(requestParameters: ApiExternalEvaluationGetAllExernalEvaluationsForProgramProgramIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExternalEvaluationReportDTOPagedResponse>> {
        if (requestParameters.programId === null || requestParameters.programId === undefined) {
            throw new runtime.RequiredError('programId','Required parameter requestParameters.programId was null or undefined when calling apiExternalEvaluationGetAllExernalEvaluationsForProgramProgramIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ExternalEvaluation/GetAllExernalEvaluationsForProgram/{programId}`.replace(`{${"programId"}}`, encodeURIComponent(String(requestParameters.programId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalEvaluationReportDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiExternalEvaluationGetAllExernalEvaluationsForProgramProgramIdGet(requestParameters: ApiExternalEvaluationGetAllExernalEvaluationsForProgramProgramIdGetRequest, initOverrides?: RequestInit): Promise<ExternalEvaluationReportDTOPagedResponse> {
        const response = await this.apiExternalEvaluationGetAllExernalEvaluationsForProgramProgramIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiExternalEvaluationGetExernalEvaluationByIdIdGetRaw(requestParameters: ApiExternalEvaluationGetExernalEvaluationByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExternalEvaluationReportDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiExternalEvaluationGetExernalEvaluationByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ExternalEvaluation/GetExernalEvaluationById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalEvaluationReportDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiExternalEvaluationGetExernalEvaluationByIdIdGet(requestParameters: ApiExternalEvaluationGetExernalEvaluationByIdIdGetRequest, initOverrides?: RequestInit): Promise<ExternalEvaluationReportDTO> {
        const response = await this.apiExternalEvaluationGetExernalEvaluationByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiExternalEvaluationUpdateExernalEvaluationIdPutRaw(requestParameters: ApiExternalEvaluationUpdateExernalEvaluationIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiExternalEvaluationUpdateExernalEvaluationIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ExternalEvaluation/UpdateExernalEvaluation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalEvaluationReportDTOToJSON(requestParameters.externalEvaluationReportDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiExternalEvaluationUpdateExernalEvaluationIdPut(requestParameters: ApiExternalEvaluationUpdateExernalEvaluationIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiExternalEvaluationUpdateExernalEvaluationIdPutRaw(requestParameters, initOverrides);
    }

}
