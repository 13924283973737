import { Card } from 'antd';
import styled from 'styled-components';
import { theme } from '../theme';


const StyledCard = styled(Card)`
  box-shadow: ${(props: any) =>
    props.boxshadow ? props.boxshadow : "rgba(0, 0, 0, 0.1) 0px 4px 12px"};
  height: 100%;
  margin-bottom: ${(props: any) =>
    props.marginbottom ? props.marginbottom : "30px"};
  width: ${(props: any) =>
    props.widthelem ? props.widthelem : "100%"};
  border-radius: 7px;
  background-color: ${(props: any) =>
    props.backgroundcolor ? props.backgroundcolor : theme.white};
  draggable

  
  &:hover,
  &:focus,
  &:active {
    box-shadow: ${(props: any) =>
    props.boxshadow ? props.boxshadow : "rgba(0, 0, 0, 0.1) 0px 4px 12px"};
    height: 100%;
    width: ${(props: any) =>
    props.widthelem ? props.widthelem : "100%"};
    margin-bottom: 30px;
    border-radius: 7px;
    background-color: ${(props: any) =>
    props.backgroundcolor ? props.backgroundcolor : theme.white};
  }
`;

const CustomCard = (props: any) => {
  return <StyledCard {...props}>{props.children}</StyledCard>;
};

export default CustomCard;
