import { useState } from 'react';
import Layout from '../../Containers/Layout';
import styles from './Login.module.scss';
import 'antd/dist/antd.min.css';
import CustomForm from '../../CustomComponents/CustomForm';
import { theme } from '../../theme';
import {
  NOTIFICATION_TYPES,
  openNotification,
} from "../Notifications/NotificationsUtils";
import { useHistory } from "react-router-dom";
import { Tabs } from 'antd';
import { Redirect } from 'react-router-dom';
import { isUserLogged } from '../../utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import { emailPattern } from '../../utils/constants';


const { TabPane } = Tabs;

//TO DO: if a company has not profile completed, redirect to profile
const Login = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [state, setState] = useState({ email: '', password: '' });

  const handleChange = (event: any, field: any) => {
    event.persist();

    setState(prevState => {
      return {
        ...prevState,
        [field]: event.target.value
      };
    });
  };

  const verifyCredentials = (event: any) => {
    if (event.key === 'Enter') {
      handleSignin();
    }
  }

  const handleSignin = () => {
    if (!state.email) {
      openNotification(
        t('loginText.login'),
        t('loginText.form.message.requiredEmail'),
        NOTIFICATION_TYPES.ERROR
      );
    } else if (!emailPattern.test(state.email)) {
      openNotification(
        t('loginText.login'),
        t('loginText.form.message.invalidEmail'),
        NOTIFICATION_TYPES.ERROR
      );
    } else if (!state.password) {
      openNotification(
        t('loginText.login'),
        t('loginText.form.message.requiredPassword'),
        NOTIFICATION_TYPES.ERROR
      );
    } else {
      const reqBody = {
        email: state.email,
        password: state.password
      };
    }
  };

  if (isUserLogged()) {
        return <Redirect to="/"/>
  } else {
    return (
      <Layout>
        <div className={styles.wrapper}>
          <div className={styles.container}>
                <CustomForm layout={theme.layout}
                  boxshadow={"none"}
                >
                  <div className={styles.content} style={{ height: "230px" }}>
                    {t('loginText.loginWithCredentials')}
                    <a href={`${process.env.REACT_APP_AUTH_API_URL}/auth?clientAction=login&clientName=https://${process.env.REACT_APP_DOMAIN}/`}>{t('loginText.accessLink')}</a>
                  </div>
                </CustomForm>
          </div>
        </div>
      </Layout >
    );
  }
};

export default Login;
