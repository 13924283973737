/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum StudyCycleEnum {
    Bachelor = 'Bachelor',
    Master = 'Master',
    Doctorate = 'Doctorate',
    All = 'All'
}

export function StudyCycleEnumFromJSON(json: any): StudyCycleEnum {
    return StudyCycleEnumFromJSONTyped(json, false);
}

export function StudyCycleEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudyCycleEnum {
    return json as StudyCycleEnum;
}

export function StudyCycleEnumToJSON(value?: StudyCycleEnum | null): any {
    return value as any;
}

