import { SenateDecisionApi, SenateDecisionDTO } from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new SenateDecisionApi(getAuthConfiguration());

export const getSenateDecision = (id: string) => {
    if (!id) {
        return {} as SenateDecisionDTO;
    }
    return ApiFactory().apiSenateDecisionGetSenateDecisionByIdIdGet({ id });
}

export const getSenateDecisionByProgramId = (programId: string, page: number = 1, pageSize: number = 10) => {
    return ApiFactory().apiSenateDecisionGetAllSenateDecisionsForProgramProgramIdGet({ page, pageSize, programId });
}

export const addSenateDecision = (senateDecisionDTO: any) => {
    return ApiFactory().apiSenateDecisionAddSenateDecisionPost({ senateDecisionAddDTO: senateDecisionDTO });
}

export const updateSenateDecision = (id: string, senateDecisionDTO: any) => { 
    return ApiFactory().apiSenateDecisionUpdateSenateDecisionIdPut({ id, senateDecisionDTO });
}

export const deleteSenateDecision = (id: string) => {
    return ApiFactory().apiSenateDecisionDeleteIdDelete({ id });
}