/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Faculty,
    FacultyFromJSON,
    FacultyToJSON,
} from '../models';

export interface ApiFacultyGetAllFacultiesGetRequest {
    namePattern?: string;
    universityCenterId?: string;
    page?: number;
    pageSize?: number;
}

export interface ApiFacultyGetByIdIdGetRequest {
    id: string;
}

/**
 * 
 */
export class FacultyApi extends runtime.BaseAPI {

    /**
     */
    async apiFacultyGetAllFacultiesGetRaw(requestParameters: ApiFacultyGetAllFacultiesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Faculty>>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.universityCenterId !== undefined) {
            queryParameters['UniversityCenterId'] = requestParameters.universityCenterId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Faculty/GetAllFaculties`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FacultyFromJSON));
    }

    /**
     */
    async apiFacultyGetAllFacultiesGet(requestParameters: ApiFacultyGetAllFacultiesGetRequest = {}, initOverrides?: RequestInit): Promise<Array<Faculty>> {
        const response = await this.apiFacultyGetAllFacultiesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFacultyGetByIdIdGetRaw(requestParameters: ApiFacultyGetByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Faculty>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFacultyGetByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Faculty/GetById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FacultyFromJSON(jsonValue));
    }

    /**
     */
    async apiFacultyGetByIdIdGet(requestParameters: ApiFacultyGetByIdIdGetRequest, initOverrides?: RequestInit): Promise<Faculty> {
        const response = await this.apiFacultyGetByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
