/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudyProgram,
    StudyProgramFromJSON,
    StudyProgramFromJSONTyped,
    StudyProgramToJSON,
} from './StudyProgram';

/**
 * 
 * @export
 * @interface SenateDecision
 */
export interface SenateDecision {
    /**
     * 
     * @type {string}
     * @memberof SenateDecision
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof SenateDecision
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SenateDecision
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SenateDecision
     */
    reportDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SenateDecision
     */
    filePath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SenateDecision
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SenateDecision
     */
    studyProgramId?: string;
    /**
     * 
     * @type {StudyProgram}
     * @memberof SenateDecision
     */
    studyProgram?: StudyProgram;
}

export function SenateDecisionFromJSON(json: any): SenateDecision {
    return SenateDecisionFromJSONTyped(json, false);
}

export function SenateDecisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SenateDecision {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'reportDate': !exists(json, 'reportDate') ? undefined : (new Date(json['reportDate'])),
        'filePath': !exists(json, 'filePath') ? undefined : json['filePath'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'studyProgramId': !exists(json, 'studyProgramId') ? undefined : json['studyProgramId'],
        'studyProgram': !exists(json, 'studyProgram') ? undefined : StudyProgramFromJSON(json['studyProgram']),
    };
}

export function SenateDecisionToJSON(value?: SenateDecision | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'reportDate': value.reportDate === undefined ? undefined : (value.reportDate.toISOString()),
        'filePath': value.filePath,
        'description': value.description,
        'studyProgramId': value.studyProgramId,
        'studyProgram': StudyProgramToJSON(value.studyProgram),
    };
}

