import { ExternalEvaluationApi, ExternalEvaluationReportDTO } from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new ExternalEvaluationApi(getAuthConfiguration());

export const getExternalEvaluation = (id: string) => {
    if (!id) {
        return {} as ExternalEvaluationReportDTO;
    }
    return ApiFactory().apiExternalEvaluationGetExernalEvaluationByIdIdGet({ id });
}

export const getExternalEvaluationByProgramId = (programId: string, page: number = 1, pageSize: number = 10) => {
    return ApiFactory().apiExternalEvaluationGetAllExernalEvaluationsForProgramProgramIdGet({ page, pageSize, programId });
}

export const addExternalEvaluation = (externalEvaluationReportDTO: any) => {
    return ApiFactory().apiExternalEvaluationAddExernalEvaluationPost({ externalEvaluationReportAddDTO: externalEvaluationReportDTO });
}

export const updateExternalEvaluation = (id: string, externalEvaluationReportDTO: any) => { 
    return ApiFactory().apiExternalEvaluationUpdateExernalEvaluationIdPut({ id, externalEvaluationReportDTO });
}

export const deleteExternalEvaluation = (id: string) => {
    return ApiFactory().apiExternalEvaluationDeleteIdDelete({ id });
}