import { AcademicUserUpdateDTO, AcademicUserUpdateRoleDTO } from "../Api";
import { AcademicUserApi } from "../Api/apis";
import { arrayParamAjust } from "../utils/dataUtils";
import { getAuthConfiguration } from "./configuration";

const AuthAcademicUserApiFactory = () => new AcademicUserApi(getAuthConfiguration());

//de testat toate
export const getAllAcademicUsers = (namePattern?: string, page?: number, pageSize?: number, faculties?: string[]) => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetAllAcademicUsersGet({ namePattern, faculties: arrayParamAjust(faculties), page, pageSize });
}

export const getAdminById = (id: string) => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetAdminByIdIdGet({ id });
}

export const updateAdminInfo = async (id: string, academicUserUpdateDTO: AcademicUserUpdateDTO) => {
    await AuthAcademicUserApiFactory().apiAcademicUserUpdateAdminIdPut({ id, academicUserUpdateDTO });
}

export const updateAcademicUserRole = async (id: string, academicUserUpdateRoleDTO: AcademicUserUpdateRoleDTO) => {
    await AuthAcademicUserApiFactory().apiAcademicUserUpdateAcademicUserRoleIdPut({ id, academicUserUpdateRoleDTO });
}

export const getRolesForUser = () => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetRolesForUserGet();
}