/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SenateDecisionAddDTO
 */
export interface SenateDecisionAddDTO {
    /**
     * 
     * @type {Date}
     * @memberof SenateDecisionAddDTO
     */
    reportDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SenateDecisionAddDTO
     */
    studyProgramId?: string;
    /**
     * 
     * @type {string}
     * @memberof SenateDecisionAddDTO
     */
    description?: string | null;
}

export function SenateDecisionAddDTOFromJSON(json: any): SenateDecisionAddDTO {
    return SenateDecisionAddDTOFromJSONTyped(json, false);
}

export function SenateDecisionAddDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SenateDecisionAddDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reportDate': !exists(json, 'reportDate') ? undefined : (new Date(json['reportDate'])),
        'studyProgramId': !exists(json, 'studyProgramId') ? undefined : json['studyProgramId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function SenateDecisionAddDTOToJSON(value?: SenateDecisionAddDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reportDate': value.reportDate === undefined ? undefined : (value.reportDate.toISOString()),
        'studyProgramId': value.studyProgramId,
        'description': value.description,
    };
}

