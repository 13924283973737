/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Recommendation,
    RecommendationFromJSON,
    RecommendationFromJSONTyped,
    RecommendationToJSON,
} from './Recommendation';
import {
    StudyProgram,
    StudyProgramFromJSON,
    StudyProgramFromJSONTyped,
    StudyProgramToJSON,
} from './StudyProgram';

/**
 * 
 * @export
 * @interface ExternalEvaluationReport
 */
export interface ExternalEvaluationReport {
    /**
     * 
     * @type {string}
     * @memberof ExternalEvaluationReport
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ExternalEvaluationReport
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ExternalEvaluationReport
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ExternalEvaluationReport
     */
    reportDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ExternalEvaluationReport
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ExternalEvaluationReport
     */
    filePath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalEvaluationReport
     */
    description?: string | null;
    /**
     * 
     * @type {Array<Recommendation>}
     * @memberof ExternalEvaluationReport
     */
    recommendations?: Array<Recommendation> | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalEvaluationReport
     */
    studyProgramId?: string;
    /**
     * 
     * @type {StudyProgram}
     * @memberof ExternalEvaluationReport
     */
    studyProgram?: StudyProgram;
}

export function ExternalEvaluationReportFromJSON(json: any): ExternalEvaluationReport {
    return ExternalEvaluationReportFromJSONTyped(json, false);
}

export function ExternalEvaluationReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalEvaluationReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'reportDate': !exists(json, 'reportDate') ? undefined : (new Date(json['reportDate'])),
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'filePath': !exists(json, 'filePath') ? undefined : json['filePath'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'recommendations': !exists(json, 'recommendations') ? undefined : (json['recommendations'] === null ? null : (json['recommendations'] as Array<any>).map(RecommendationFromJSON)),
        'studyProgramId': !exists(json, 'studyProgramId') ? undefined : json['studyProgramId'],
        'studyProgram': !exists(json, 'studyProgram') ? undefined : StudyProgramFromJSON(json['studyProgram']),
    };
}

export function ExternalEvaluationReportToJSON(value?: ExternalEvaluationReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'reportDate': value.reportDate === undefined ? undefined : (value.reportDate.toISOString()),
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'filePath': value.filePath,
        'description': value.description,
        'recommendations': value.recommendations === undefined ? undefined : (value.recommendations === null ? null : (value.recommendations as Array<any>).map(RecommendationToJSON)),
        'studyProgramId': value.studyProgramId,
        'studyProgram': StudyProgramToJSON(value.studyProgram),
    };
}

