import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from '../Components/Home/Home';
import Login from '../Components/Login/Login';
import Info from '../Components/Info/Info';
import Notifications from '../Components/Notifications/Notifications';
import { useIsAdmin } from '../utils/utilFunctions';
import StudyPrograms from '../Components/StudyPrograms/StudyPrograms';
import Domains from '../Components/Domains/Domains';
import DomainForm from '../Components/Domains/DomainForm/DomainForm';
import StudyProgramForm from '../Components/StudyPrograms/StudyProgramForm/StudyProgramForm';
import ExternalEvaluations from '../Components/ExternalEvaluations/ExternalEvaluations';
import ExternalEvaluationForm from '../Components/ExternalEvaluations/ExternalEvaluationForm/ExternalEvaluationForm';
import AdminBoardDecisions from '../Components/AdminBoardDecisions/AdminBoardDecisions';
import AdminBoardDecisionForm from '../Components/AdminBoardDecisions/AdminBoardDecisionForm/AdminBoardDecisionForm';
import SenateDecisionForm from '../Components/SenateDecisions/SenateDecisionForm/SenateDecisionForm';
import SenateDecisions from '../Components/SenateDecisions/SenateDecisions';

const Router = () => {
  const isAdmin = useIsAdmin();

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/autentificare' component={Login} />
        <Route path='/info' component={Info} />
        <Route path='/notificari' component={Notifications} />
        <Route path='/:studyCycle/programe' component={StudyPrograms} />
        <Route path='/:studyCycle/editare-program/:programId' component={StudyProgramForm} />
        <Route path='/:studyCycle/adaugare-program' component={StudyProgramForm} />
        <Route path='/domenii' component={Domains} />
        <Route path='/editare-domeniu/:domainId' component={DomainForm} />
        <Route path='/adaugare-domeniu' component={DomainForm} />
        <Route path='/:studyCycle/editare-evaluare/:programId/:externalEvaluationId' component={ExternalEvaluationForm} />
        <Route path='/:studyCycle/adaugare-evaluare/:programId' component={ExternalEvaluationForm} />
        <Route path='/:studyCycle/evaluari-externe/:programId' component={ExternalEvaluations} />
        <Route path='/:studyCycle/editare-admin/:programId/:adminBoardDecisionId' component={AdminBoardDecisionForm} />
        <Route path='/:studyCycle/adaugare-admin/:programId' component={AdminBoardDecisionForm} />
        <Route path='/:studyCycle/hotarari-ca/:programId' component={AdminBoardDecisions} />
        <Route path='/:studyCycle/editare-senat/:programId/:senateDecisionId' component={SenateDecisionForm} />
        <Route path='/:studyCycle/adaugare-senat/:programId' component={SenateDecisionForm} />
        <Route path='/:studyCycle/hotarari-senat/:programId' component={SenateDecisions} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;