export const enum theme {
  //verde deschis
  primaryColor = '#1890ff',
  primaryColorACS = '#21548A',
  //verde inchis
  secondColor = '#1c3334',
  secondColorACS = '#19A1B6',
  thirdColorACS = '#277BC0',
  lightGreen = '#add8e6',
  lightBlue = '#add8e6',
  darkBlue = '#002B5B',
  darkBlue2 = '#25316D',
  //alb
  white = 'white',
//gri deschis
  lightGray = '#E8E8E8',
  disabledGray = '#F5F5F5',
  //gri
  forthColor = '#DBD7D7',
  //rosu opac
  redOpac = 'rgb(255,0,0, 0.7)',
  //rosu 
  red = 'rgba(255,0,0)',
  //verde opac
  green = 'rgb(24, 144, 255, 0.7)',
  greenOpac = 'rgb(24, 144, 255, 0.2)',
  //negru
  black = 'black',
  //font text
  font = 'Montserrat',
  layout = 'vertical',
  headerSizeIcons = '22px'
}
