import { FileManagerApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new FileManagerApi(getAuthConfiguration());

export const uploadExternalEvaluation = (externalEvaluationId: string, file: Blob) => {
    return ApiFactory().apiFileManagerUploadExternalEvaluationExternalEvaluationIdPost({ externalEvaluationId, file });
}

export const downloadExternalEvaluation = (externalEvaluationId: string) => {
    return ApiFactory().apiFileManagerDownloadExternalEvaluationExternalEvaluationIdGet({ externalEvaluationId });
}

export const uploadAdminBoardDecision = (adminBoardDecisionId: string, file: Blob) => {
    return ApiFactory().apiFileManagerUploadAdminBoardDecisionAdminBoardDecisionIdPost({ adminBoardDecisionId, file });
}

export const downloadAdminBoardDecision = (adminBoardDecisionId: string) => {
    return ApiFactory().apiFileManagerDownloadAdminBoardDecisionAdminBoardDecisionIdGet({ adminBoardDecisionId });
}

export const uploadSenateDecision = (senateDecisionId: string, file: Blob) => {
    return ApiFactory().apiFileManagerUploadSenateDecisionSenateDecisionIdPost({ senateDecisionId, file });
}

export const downloadSenateDecision = (senateDecisionId: string) => {
    return ApiFactory().apiFileManagerDownloadSenateDecisionSenateDecisionIdGet({ senateDecisionId });
}

export const uploadAncFile = (studyProgramId: string, file: Blob) => {
    return ApiFactory().apiFileManagerUploadAncFileStudyProgramIdPost({ studyProgramId, file });
}

export const downloadAncFile = (studyProgramId: string) => {
    return ApiFactory().apiFileManagerDownloadAncFileStudyProgramIdGet({ studyProgramId });
}

export const uploadAracisFile = (studyProgramId: string, file: Blob) => {
    return ApiFactory().apiFileManagerUploadAracisRequestStudyProgramIdPost({ studyProgramId, file });
}

export const downloadAracisFile = (studyProgramId: string) => {
    return ApiFactory().apiFileManagerDownloadAracisRequestStudyProgramIdGet({ studyProgramId });
}