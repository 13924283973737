import styles from './Footer.module.scss';
import { Link } from 'react-router-dom';
import logo from '../../Media/logo UNSTPB alb.png';
import crescdi from "../../Media/logo webzy.svg";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { isUserLogged } from "../../utils/utilFunctions";
import {Tooltip} from "antd";


const Footer = () => {
  const { t } = useTranslation();
  
  return (
    <div className={styles.footerContainer}>
      <div className={styles.firstline}>
        <div className={styles.firstColumn}>
          <img alt={'logo'} src={logo} style={{width: "auto", height: "10vh", marginLeft: "5vw"}}/>
        </div>
        <div className={styles.secondColumn}>
          <div className={styles.firstChild}>
            <h1>{t('footer.public')}</h1>
            <div className={styles.middle}>
              <div className={styles.first}>
                <Tooltip title={t('header.home')}>
                  <Link to='/'><FontAwesomeIcon style={{marginLeft: '0px', marginRight: '2px'}} icon={solid('house')}/> Acasă</Link>
                </Tooltip>
                {/* {(isUserLogged()) ?
                    <Tooltip title='Propunerile mele'>
                      <Link to='/propuneri'><FontAwesomeIcon  icon={solid('graduation-cap')}/> Propunerile mele</Link>
                    </Tooltip>
                : null} */}
              
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className={styles.secondline}>
        <img src={crescdi} alt={"crescdi logo"} style={{height: "5vh"}}/>
        <h1>{'Copyright © ' + new Date().getFullYear() }</h1>
        <div className={styles.middle}>
          <ul className={styles.footerLi}>
            {/* <li ><Link to='/info'>{t('footer.aboutUs')}</Link></li> */}
            {/* <li ><Link to='/termeni'>{t('footer.terms')}</Link></li> */}
            {/* <li ><Link to='/politica&confidentialitate'>{t('footer.gdpr')}</Link></li> */}
            <li ><a href="https://support.upb.ro/" target="_blank" rel={"noreferrer"} className="tnc__21mP0">{t('footer.suport')}</a></li>
          </ul>
        </div>
      </div>
    </div >
  );
};

export default Footer;
