import {  useState } from "react";
import styles from "./AdminBoardDecisions.module.scss";
import { Button, Modal, Table, TablePaginationConfig, Tooltip, Typography } from "antd";
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import Layout from "../../Containers/Layout";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowLeftOutlined, InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { Link, useParams  } from "react-router-dom";
import { theme } from "../../theme";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import { AdminBoardDecisionDTO, StudyCycleEnum } from "../../Api";
import { deleteAdminBoardDecision, getAdminBoardDecisionByProgramId } from "../../Requests/admin-board-decision-requests";
import { downloadFile } from "../../utils/downloadUtils";
import { downloadAdminBoardDecision, uploadAdminBoardDecision } from "../../Requests/file-manager-requests";
import { dateFormat } from "../../utils/constants";
import moment from "moment";
import Dragger from "antd/lib/upload/Dragger";

interface PageResponse<T> {
    data?: T[] | null,
    totalCount?: number,
    page?: number,
    pageSize?: number
}

export type PageCallBack<T> = (currentPage: number, pageSize: number) => Promise<PageResponse<T> | null | undefined>;
export type ColumnType<T> = { title: string, key: any, dataIndex?: string, width?: any, render?: (text: string, record: T, index: number) => any };

const AdminBoardDecisions = () => {
    const { t } = useTranslation();
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrent] = useState(1);

  const { programId, studyCycle } = useParams<{ programId: string, studyCycle: StudyCycleEnum }>();

    //Upload modal
    const uploadSucceded = () => {
        handleClose();
        openNotification(
            'Succes',
            'Încarcarea s-a realizat cu succes!',
            NOTIFICATION_TYPES.SUCCESS
        );
    };

    const uploadFailed = (_err: any) => {
        openNotification(
            'Eroare',
            'Încarcarea nu s-a realizat cu succes!',
            NOTIFICATION_TYPES.ERROR
        );
    };

    const wrongFile = () => {
        openNotification(
            'Eroare',
            'Extensia fișierului selectat nu este permisă!',
            NOTIFICATION_TYPES.ERROR
        );
    };

    const upload = async (file: Blob | null) => {
        if (file) {
            await modalProps.uploadHandler(modalProps.adminBoardDecisionId, file).then(uploadSucceded);
        }
    }

    const [modalProps, setModalProps] = useState({
        isModalVisible: false,
        title: '',
        fileExtension: '',
        adminBoardDecisionId: '',
        uploadHandler: uploadAdminBoardDecision
    });
    
    const handleShow = (id: string, uploadHandler: any, fileExtension: string = 'pdf') => {
        setModalProps(modalProps => ({...modalProps, isModalVisible: true, id, uploadHandler, fileExtension, adminBoardDecisionId: id}));
    }

    const handleClose = () => {
        setModalProps(modalProps => ({...modalProps, isModalVisible: false}));
    }
    
    const handleOk = () => {
        setModalProps(modalProps => ({...modalProps, isModalVisible: false}));
        
    }
    

    //Delete confirmation modal
    const [modalPropsDelete, setModalPropsDelete] = useState({
        isModalVisible: false,
        title: '',
        id: '',
    });
    const handleShowDelete = (id: string) => {
        setModalPropsDelete(modalPropsDelete => ({...modalPropsDelete, isModalVisible: true, id}));
    }

    const handleCloseDelete = () => {
        setModalPropsDelete(modalPropsDelete => ({...modalPropsDelete, isModalVisible: false}));
    }

    const handleOkDelete = () => {
        setModalPropsDelete(modalPropsDelete => ({...modalPropsDelete, isModalVisible: false}));
        deleteAdminBoardDecision(modalPropsDelete.id)
            .then(() => {
                deleteSucceded();
                invalidateQuery();
            })
            .catch(deleteFailed);

    }

    // const handleShowRecommendations = (record: AdminBoardDecisionDTO) => {
    //     setRecommendationModalProps(recommendationModalProps => ({...recommendationModalProps, isModalVisible: true, title: "Programe asociate domeniului"}));
    // }
  
    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrent(() => pagination.current ?? 1);
        setPageSize(() => pagination.pageSize ?? 10);
    };

    const deleteSucceded = () => {
        openNotification(
            'Succes',
            'Ștergerea s-a realizat cu succes!',
            NOTIFICATION_TYPES.SUCCESS
        );
    };

    const deleteFailed = (_err: any) => {
        openNotification(
            'Eroare',
            'Ștergerea nu s-a realizat cu succes!',
            NOTIFICATION_TYPES.ERROR
        );
    };

    const queryClient = useQueryClient();

    const invalidateQuery = () => { queryClient.invalidateQueries('getAdminBoardDecisions') };

    const openFetchError = (_error: any) => {
        openNotification(
            'Eroare!',
            'Datele nu au putut fi aduse din server!',
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data, isLoading } = useQuery(['getAdminBoardDecisions', programId, pageSize, currentPage],
        () => {
            return getAdminBoardDecisionByProgramId(programId, currentPage, pageSize);
        },
        {
            onError: openFetchError,
        }
    );

    const columns = [
        {
            title: "Data hotărârii",
            dataIndex: 'reportDate',
            width: '130px',
            key: 'reportDate',
            render:(date: Date) => { return date?.toLocaleDateString("ro-RO") }
        },
        {
            title: "Descriere",
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Acțiuni',
            width: '200px',
            render: (text: string, record: any, index: number) => 
                <div>
                    <Tooltip title='Descărcare'>
                        <FontAwesomeIcon icon={solid('download')} style={{ marginLeft: '20px', cursor: 'pointer', height: "1rem", padding: "6px 0px" }}
                        onClick={() => downloadFile(downloadAdminBoardDecision(record.id), 'Decizia administrativa din ' + moment(record.reportDate).format(dateFormat))}/>
                    </Tooltip>
                    <Tooltip title='Încărcare'>
                        <FontAwesomeIcon icon={solid('upload')} style={{ marginLeft: '20px', cursor: 'pointer', height: "1rem", padding: "6px 0px" }}
                        onClick={() => handleShow(record.id!, uploadAdminBoardDecision, 'pdf')}/>
                    </Tooltip>
                    <Link to={{ pathname: '/' + studyCycle + "/editare-admin/" + programId + '/' + record.id}}><Tooltip title='Editare'>
                        <FontAwesomeIcon icon={solid('edit')} style={{ marginLeft: '20px', cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                    </Tooltip></Link>
                    <Tooltip title='Ștergere'>
                        <FontAwesomeIcon icon={solid('trash')} style={{ marginLeft: '20px', cursor: 'pointer', height: "1rem", padding: "6px 0px" }}
                        onClick={() => handleShowDelete(record.id)}/>
                    </Tooltip>
                </div>
        }
    ];


    return <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
        <Layout>
            <Modal
                open={modalPropsDelete.isModalVisible}
                onOk={handleOkDelete}
                onCancel={handleCloseDelete}
                title="Ștergere"
                width={"400px"}
                okText={t('account.confirm')}
                cancelText={t('account.cancel')}
                cancelButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white, borderRadius: "10px" } }}
                okButtonProps={{ style: { background: theme.secondColor, border: theme.secondColor, borderRadius: "10px" } }}
                >
                    <div>
                        <Typography>Sigur doriti stergerea deciziei administrative?</Typography>
                    </div>
            </Modal>
            <Modal
                open={modalProps.isModalVisible}
                closable={false}
                onOk={handleOk}
                onCancel={handleClose}
                title="Încărcare fișier"
                width={"50%"}
                cancelText='Anulare'
                cancelButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white, borderRadius: "10px" } }}
                okButtonProps={{ style: { display: 'none' } }}
                >
                    <div>
                    <Dragger
                        multiple={false}
                        beforeUpload={(uploaded) => { 
                            if (uploaded.name.includes(modalProps.fileExtension)) {
                                upload(uploaded).then(invalidateQuery).catch(uploadFailed);
                            }
                            else {
                                wrongFile();
                            }
                            return false; 
                        }}
                        showUploadList={false}
                    >
                        <p className="ant-upload-drag-icon" >
                            <InboxOutlined style={{color: theme.green}}/>
                        </p>
                        <p className="ant-upload-text">Dă click aici sau trage fișierul pe care vrei să îl încarci în acest chenar</p>
                        <p className="ant-upload-hint">
                            Extensii acceptate: {modalProps.fileExtension}
                        </p>
                    </Dragger>
                    </div>
            </Modal>
            <h2>Hotărâri Consiliu de Administrație</h2>
            <Table
                className={styles.usersTable}
                dataSource={data?.data || []}
                pagination={{
                    total: data?.totalCount,
                    current: currentPage,
                    pageSize: data?.pageSize,
                    pageSizeOptions: ["10", "20", "50"],
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    showTotal: (total: number, range: [number, number]) => {
                        return <div>
                                <Tooltip title="Înapoi">
                                    <Link to={{ pathname: '/' + studyCycle + '/programe'}} >
                                        <Button type="primary" icon={<ArrowLeftOutlined />} style={{marginRight: 10}}>
                                            Înapoi
                                        </Button>
                                    </Link>
                                </Tooltip>
                                <Tooltip title="Adaugă hotărâre">
                                    <Link to={{ pathname: '/' + studyCycle + '/adaugare-admin/' + programId}} >
                                        <Button type="primary" icon={<PlusOutlined />}>
                                            Adaugă hotărâre
                                        </Button>
                                    </Link>
                                </Tooltip>
                            </div>
                    },
                    hideOnSinglePage: false,
                    locale: { items_per_page: t('configuration.pagination')},
                    position: ['topRight', 'bottomRight']
                }}
                locale={{
                    emptyText: (
                        <span>
                            <h3>Nu există Hotărâri ale Consiliului de Administrație adăugate! Apasă butonul de mai jos pentru a adăuga o nouă hotărâre!</h3>
                            <Tooltip title="Adaugă hotărâre">
                                <Link to={{ pathname: '/' + studyCycle + '/adaugare-admin/' + programId}} >
                                    <Button type="primary" icon={<PlusOutlined />}>
                                    Adaugă hotărâre
                                    </Button>
                                </Link>
                            </Tooltip>
                            <br/>
                            <br/>
                            <Tooltip title="Înapoi">
                                <Link to={{ pathname: '/' + studyCycle + '/programe'}} >
                                    <Button type="primary" icon={<ArrowLeftOutlined />} style={{marginRight: 10}}>
                                        Înapoi
                                    </Button>
                                </Link>
                            </Tooltip>
                        </span>
                      )
                }}
                columns={columns}
                rowKey={record => record.id ?? ''}
                loading={isLoading}
                onChange={handleTableChange}
            />
        </Layout>
    </div>
}

export default AdminBoardDecisions;
