/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SimpleStudyCycleEnum,
    SimpleStudyCycleEnumFromJSON,
    SimpleStudyCycleEnumFromJSONTyped,
    SimpleStudyCycleEnumToJSON,
} from './SimpleStudyCycleEnum';

/**
 * 
 * @export
 * @interface DomainUpdateDTO
 */
export interface DomainUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof DomainUpdateDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DomainUpdateDTO
     */
    fundamentalName?: string | null;
    /**
     * 
     * @type {SimpleStudyCycleEnum}
     * @memberof DomainUpdateDTO
     */
    studyCycle?: SimpleStudyCycleEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DomainUpdateDTO
     */
    facultyIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DomainUpdateDTO
     */
    studyProgramIds?: Array<string> | null;
}

export function DomainUpdateDTOFromJSON(json: any): DomainUpdateDTO {
    return DomainUpdateDTOFromJSONTyped(json, false);
}

export function DomainUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DomainUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'fundamentalName': !exists(json, 'fundamentalName') ? undefined : json['fundamentalName'],
        'studyCycle': !exists(json, 'studyCycle') ? undefined : SimpleStudyCycleEnumFromJSON(json['studyCycle']),
        'facultyIds': !exists(json, 'facultyIds') ? undefined : json['facultyIds'],
        'studyProgramIds': !exists(json, 'studyProgramIds') ? undefined : json['studyProgramIds'],
    };
}

export function DomainUpdateDTOToJSON(value?: DomainUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'fundamentalName': value.fundamentalName,
        'studyCycle': SimpleStudyCycleEnumToJSON(value.studyCycle),
        'facultyIds': value.facultyIds,
        'studyProgramIds': value.studyProgramIds,
    };
}

