import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import i18n from "../../../i18n";
import { RecomandationStatusEnum, RecommendationDTO } from "../../../Api";

const { Option } = Select;
const { List, Item } = Form;

const RecommendationForm = (props: { name: string, initialValue: RecommendationDTO[], isAdd: boolean}) => {
    const { t } = i18n;
    console.log(props.isAdd);
    
    return <List initialValue={props.initialValue} name={props.name} >
        {(fields, { add, remove }) => (
            <div>
                <label style={{ marginBottom: "8px" }}>{'* Recomandări:'}</label>
                {fields.map(({ key, name, ...restField }) => (
                    <Space
                        key={key}
                        style={{
                            display: 'flex',
                            marginBottom: 8,
                            width: '100%',
                        }}
                        className="recommendations-from"
                        align="baseline"
                    >
                        <Row gutter={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                            <Col xs={24} sm={24} md={24} lg={props.isAdd ? 18 : 24} xl={props.isAdd ? 18 : 24}>
                                <Item
                                    {...restField}
                                    name={[name, 'description']}
                                    initialValue=''
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: false,
                                            message: t('competences.missingField'),
                                        },
                                    ]}
                                >
                                    <Input placeholder='Descriere' />
                                </Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={props.isAdd ? 4 : 12} xl={props.isAdd ? 5 : 12}>
                                <Item
                                    {...restField}
                                    name={[name, 'officialName']}
                                    initialValue=''
                                    rules={[
                                        {
                                            required: true,
                                            message: t('competences.missingField'),
                                        },
                                    ]}
                                >
                                    <Input placeholder='Responsabil' />
                                </Item>
                            </Col>
                            { !props.isAdd && <Col xs={22} sm={22} md={22} lg={10} xl={11}>
                                <Item
                                    {...restField}
                                    name={[name, 'state']}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('competences.missingField'),
                                        },
                                    ]}
                                >
                                    <Select
                                        allowClear
                                        placeholder='Status'
                                    >
                                        <Option value={RecomandationStatusEnum.Neindeplinit}>Neîndeplinit</Option>
                                        <Option value={RecomandationStatusEnum.Indeplinit}>Îndeplinit</Option>
                                        <Option value={RecomandationStatusEnum.PartialIndeplinit}>Parțial Îndeplinit</Option>
                                        <Option value={RecomandationStatusEnum.Inaplicabil}>Inaplicabil</Option>
                                    </Select>
                                </Item>
                            </Col>}
                            <Col xs={2} sm={2} md={2} lg={2} xl={1}>
                                {fields.length > 1 && <MinusCircleOutlined style={{marginTop: '8px'}} onClick={() => remove(name)} />}
                            </Col>
                            
                        </Row>
                    </Space>
                ))}
                <Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Adaugă recomandare
                    </Button>
                </Item>
            </div>
        )}
    </List>
};

export default RecommendationForm;