/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MasterCategoryEnum {
    NotApplied = 'NotApplied',
    Professional = 'Professional',
    Research = 'Research',
    Teaching = 'Teaching'
}

export function MasterCategoryEnumFromJSON(json: any): MasterCategoryEnum {
    return MasterCategoryEnumFromJSONTyped(json, false);
}

export function MasterCategoryEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MasterCategoryEnum {
    return json as MasterCategoryEnum;
}

export function MasterCategoryEnumToJSON(value?: MasterCategoryEnum | null): any {
    return value as any;
}

