import { AdminBoardDecisionApi, AdminBoardDecisionDTO } from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new AdminBoardDecisionApi(getAuthConfiguration());

export const getAdminBoardDecision = (id: string) => {
    if (!id) {
        return {} as AdminBoardDecisionDTO;
    }
    return ApiFactory().apiAdminBoardDecisionGetAdminBoardDecisionByIdIdGet({ id });
}

export const getAdminBoardDecisionByProgramId = (programId: string, page: number = 1, pageSize: number = 10) => {
    return ApiFactory().apiAdminBoardDecisionGetAllAdminBoardDecisionsForProgramProgramIdGet({ page, pageSize, programId });
}

export const addAdminBoardDecision = (adminBoardDecisonDTO: any) => {
    return ApiFactory().apiAdminBoardDecisionAddAdminBoardDecisionPost({ adminBoardDecisionAddDTO: adminBoardDecisonDTO });
}

export const updateAdminBoardDecision = (id: string, adminBoardDecisionDTO: any) => { 
    return ApiFactory().apiAdminBoardDecisionUpdateAdminBoardDecisionIdPut({ id, adminBoardDecisionDTO });
}

export const deleteAdminBoardDecision = (id: string) => {
    return ApiFactory().apiAdminBoardDecisionDeleteIdDelete({ id });
}