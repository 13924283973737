import { FacultyApi, FacultyDTO } from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new FacultyApi(getAuthConfiguration());

export const getFaculty = (id: string) => {
    return ApiFactory().apiFacultyGetByIdIdGet({ id });
}

export const getFaculties = (namePattern?: string, universityCenterId?: string, page: number = 1, pageSize: number = 10) => {
    return ApiFactory().apiFacultyGetAllFacultiesGet({ page, pageSize, namePattern, universityCenterId });
}
