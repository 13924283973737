import { NOTIFICATION_TYPES, openNotification } from "../Components/Notifications/NotificationsUtils";
import * as excel from 'exceljs';
import { saveAs } from 'file-saver';

export enum MimeType {
    docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pdf = 'application/pdf'
}

export async function downloadFile(request: Promise<Blob>, fileName: string, type : string = '') {
    request.then(file => {
        if (type === '') {
            type = file.type;
        }
        const blob = new Blob([file], {type} );
        const blobUrl = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.setAttribute('type', 'hidden');
        a.href = blobUrl;
        a.download = fileName;
        a.click();
        a.remove();
        downloadSucceeded();

    })
    .catch(downloadFailed);
}

const downloadFailed = (_err: any) => {
    openNotification(
        'Eroare',
        'Descărcarea nu s-a realizat cu succes!',
        NOTIFICATION_TYPES.ERROR
    );
};

const downloadSucceeded = () => {
    openNotification(
        'Succes',
        'Descărcarea s-a realizat cu succes!',
        NOTIFICATION_TYPES.SUCCESS
    );
};

export async function downloadExcel(fileName: string, columns: { header: string, key: string }[], objs?: any[] | null) {
    if (objs === undefined || objs === null) {
        return;
    }

    const workbook = new excel.Workbook();
    const sheet = workbook.addWorksheet(fileName);
    sheet.columns = columns;
    sheet.addRows(objs);
    adjustColumnWidth(sheet);
    
    const excelBuffer = await workbook.xlsx.writeBuffer();

    saveAs(
        new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }),
        `${fileName}.xlsx`
    );
};

export function adjustColumnWidth(worksheet: excel.Worksheet) {
    worksheet.columns.forEach(column => {
      const lengths = column?.values?.map(v => v?.toString().length ?? 0) ?? [];
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
      column.width = maxLength;
    });
  }