/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RecommendationDTO,
    RecommendationDTOFromJSON,
    RecommendationDTOFromJSONTyped,
    RecommendationDTOToJSON,
} from './RecommendationDTO';

/**
 * 
 * @export
 * @interface ExternalEvaluationReportDTO
 */
export interface ExternalEvaluationReportDTO {
    /**
     * 
     * @type {string}
     * @memberof ExternalEvaluationReportDTO
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ExternalEvaluationReportDTO
     */
    reportDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ExternalEvaluationReportDTO
     */
    startDate?: Date;
    /**
     * 
     * @type {Array<RecommendationDTO>}
     * @memberof ExternalEvaluationReportDTO
     */
    recommendations?: Array<RecommendationDTO> | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalEvaluationReportDTO
     */
    studyProgramId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalEvaluationReportDTO
     */
    studyProgramName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalEvaluationReportDTO
     */
    description?: string | null;
    /**
     * 
     * @type {Blob}
     * @memberof ExternalEvaluationReportDTO
     */
    file?: Blob | null;
}

export function ExternalEvaluationReportDTOFromJSON(json: any): ExternalEvaluationReportDTO {
    return ExternalEvaluationReportDTOFromJSONTyped(json, false);
}

export function ExternalEvaluationReportDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalEvaluationReportDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'reportDate': !exists(json, 'reportDate') ? undefined : (new Date(json['reportDate'])),
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'recommendations': !exists(json, 'recommendations') ? undefined : (json['recommendations'] === null ? null : (json['recommendations'] as Array<any>).map(RecommendationDTOFromJSON)),
        'studyProgramId': !exists(json, 'studyProgramId') ? undefined : json['studyProgramId'],
        'studyProgramName': !exists(json, 'studyProgramName') ? undefined : json['studyProgramName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'file': !exists(json, 'file') ? undefined : json['file'],
    };
}

export function ExternalEvaluationReportDTOToJSON(value?: ExternalEvaluationReportDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reportDate': value.reportDate === undefined ? undefined : (value.reportDate.toISOString()),
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'recommendations': value.recommendations === undefined ? undefined : (value.recommendations === null ? null : (value.recommendations as Array<any>).map(RecommendationDTOToJSON)),
        'studyProgramId': value.studyProgramId,
        'studyProgramName': value.studyProgramName,
        'description': value.description,
        'file': value.file,
    };
}

