/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminBoardDecisionDTO
 */
export interface AdminBoardDecisionDTO {
    /**
     * 
     * @type {string}
     * @memberof AdminBoardDecisionDTO
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof AdminBoardDecisionDTO
     */
    reportDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof AdminBoardDecisionDTO
     */
    studyProgramId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminBoardDecisionDTO
     */
    studyProgramName?: string | null;
    /**
     * 
     * @type {Blob}
     * @memberof AdminBoardDecisionDTO
     */
    file?: Blob | null;
    /**
     * 
     * @type {string}
     * @memberof AdminBoardDecisionDTO
     */
    description?: string | null;
}

export function AdminBoardDecisionDTOFromJSON(json: any): AdminBoardDecisionDTO {
    return AdminBoardDecisionDTOFromJSONTyped(json, false);
}

export function AdminBoardDecisionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminBoardDecisionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'reportDate': !exists(json, 'reportDate') ? undefined : (new Date(json['reportDate'])),
        'studyProgramId': !exists(json, 'studyProgramId') ? undefined : json['studyProgramId'],
        'studyProgramName': !exists(json, 'studyProgramName') ? undefined : json['studyProgramName'],
        'file': !exists(json, 'file') ? undefined : json['file'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function AdminBoardDecisionDTOToJSON(value?: AdminBoardDecisionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reportDate': value.reportDate === undefined ? undefined : (value.reportDate.toISOString()),
        'studyProgramId': value.studyProgramId,
        'studyProgramName': value.studyProgramName,
        'file': value.file,
        'description': value.description,
    };
}

