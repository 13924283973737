/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminBoardDecisionAddDTO,
    AdminBoardDecisionAddDTOFromJSON,
    AdminBoardDecisionAddDTOToJSON,
    AdminBoardDecisionDTO,
    AdminBoardDecisionDTOFromJSON,
    AdminBoardDecisionDTOToJSON,
    AdminBoardDecisionDTOPagedResponse,
    AdminBoardDecisionDTOPagedResponseFromJSON,
    AdminBoardDecisionDTOPagedResponseToJSON,
} from '../models';

export interface ApiAdminBoardDecisionAddAdminBoardDecisionPostRequest {
    adminBoardDecisionAddDTO?: AdminBoardDecisionAddDTO;
}

export interface ApiAdminBoardDecisionDeleteIdDeleteRequest {
    id: string;
}

export interface ApiAdminBoardDecisionGetAdminBoardDecisionByIdIdGetRequest {
    id: string;
}

export interface ApiAdminBoardDecisionGetAllAdminBoardDecisionsForProgramProgramIdGetRequest {
    programId: string;
    page?: number;
    pageSize?: number;
}

export interface ApiAdminBoardDecisionUpdateAdminBoardDecisionIdPutRequest {
    id: string;
    adminBoardDecisionDTO?: AdminBoardDecisionDTO;
}

/**
 * 
 */
export class AdminBoardDecisionApi extends runtime.BaseAPI {

    /**
     */
    async apiAdminBoardDecisionAddAdminBoardDecisionPostRaw(requestParameters: ApiAdminBoardDecisionAddAdminBoardDecisionPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdminBoardDecisionDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AdminBoardDecision/AddAdminBoardDecision`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminBoardDecisionAddDTOToJSON(requestParameters.adminBoardDecisionAddDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminBoardDecisionDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminBoardDecisionAddAdminBoardDecisionPost(requestParameters: ApiAdminBoardDecisionAddAdminBoardDecisionPostRequest = {}, initOverrides?: RequestInit): Promise<AdminBoardDecisionDTO> {
        const response = await this.apiAdminBoardDecisionAddAdminBoardDecisionPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminBoardDecisionDeleteIdDeleteRaw(requestParameters: ApiAdminBoardDecisionDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdminBoardDecisionDeleteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AdminBoardDecision/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAdminBoardDecisionDeleteIdDelete(requestParameters: ApiAdminBoardDecisionDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAdminBoardDecisionDeleteIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAdminBoardDecisionGetAdminBoardDecisionByIdIdGetRaw(requestParameters: ApiAdminBoardDecisionGetAdminBoardDecisionByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdminBoardDecisionDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdminBoardDecisionGetAdminBoardDecisionByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AdminBoardDecision/GetAdminBoardDecisionById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminBoardDecisionDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminBoardDecisionGetAdminBoardDecisionByIdIdGet(requestParameters: ApiAdminBoardDecisionGetAdminBoardDecisionByIdIdGetRequest, initOverrides?: RequestInit): Promise<AdminBoardDecisionDTO> {
        const response = await this.apiAdminBoardDecisionGetAdminBoardDecisionByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminBoardDecisionGetAllAdminBoardDecisionsForProgramProgramIdGetRaw(requestParameters: ApiAdminBoardDecisionGetAllAdminBoardDecisionsForProgramProgramIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdminBoardDecisionDTOPagedResponse>> {
        if (requestParameters.programId === null || requestParameters.programId === undefined) {
            throw new runtime.RequiredError('programId','Required parameter requestParameters.programId was null or undefined when calling apiAdminBoardDecisionGetAllAdminBoardDecisionsForProgramProgramIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AdminBoardDecision/GetAllAdminBoardDecisionsForProgram/{programId}`.replace(`{${"programId"}}`, encodeURIComponent(String(requestParameters.programId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminBoardDecisionDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminBoardDecisionGetAllAdminBoardDecisionsForProgramProgramIdGet(requestParameters: ApiAdminBoardDecisionGetAllAdminBoardDecisionsForProgramProgramIdGetRequest, initOverrides?: RequestInit): Promise<AdminBoardDecisionDTOPagedResponse> {
        const response = await this.apiAdminBoardDecisionGetAllAdminBoardDecisionsForProgramProgramIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminBoardDecisionUpdateAdminBoardDecisionIdPutRaw(requestParameters: ApiAdminBoardDecisionUpdateAdminBoardDecisionIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdminBoardDecisionUpdateAdminBoardDecisionIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AdminBoardDecision/UpdateAdminBoardDecision/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminBoardDecisionDTOToJSON(requestParameters.adminBoardDecisionDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAdminBoardDecisionUpdateAdminBoardDecisionIdPut(requestParameters: ApiAdminBoardDecisionUpdateAdminBoardDecisionIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAdminBoardDecisionUpdateAdminBoardDecisionIdPutRaw(requestParameters, initOverrides);
    }

}
