/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ApiFileManagerDownloadAdminBoardDecisionAdminBoardDecisionIdGetRequest {
    adminBoardDecisionId: string;
}

export interface ApiFileManagerDownloadAncFileStudyProgramIdGetRequest {
    studyProgramId: string;
}

export interface ApiFileManagerDownloadAracisRequestStudyProgramIdGetRequest {
    studyProgramId: string;
}

export interface ApiFileManagerDownloadExternalEvaluationExternalEvaluationIdGetRequest {
    externalEvaluationId: string;
}

export interface ApiFileManagerDownloadSenateDecisionSenateDecisionIdGetRequest {
    senateDecisionId: string;
}

export interface ApiFileManagerUploadAdminBoardDecisionAdminBoardDecisionIdPostRequest {
    adminBoardDecisionId: string;
    file?: Blob;
}

export interface ApiFileManagerUploadAncFileStudyProgramIdPostRequest {
    studyProgramId: string;
    file?: Blob;
}

export interface ApiFileManagerUploadAracisRequestStudyProgramIdPostRequest {
    studyProgramId: string;
    file?: Blob;
}

export interface ApiFileManagerUploadExternalEvaluationExternalEvaluationIdPostRequest {
    externalEvaluationId: string;
    file?: Blob;
}

export interface ApiFileManagerUploadSenateDecisionSenateDecisionIdPostRequest {
    senateDecisionId: string;
    file?: Blob;
}

/**
 * 
 */
export class FileManagerApi extends runtime.BaseAPI {

    /**
     */
    async apiFileManagerDownloadAdminBoardDecisionAdminBoardDecisionIdGetRaw(requestParameters: ApiFileManagerDownloadAdminBoardDecisionAdminBoardDecisionIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.adminBoardDecisionId === null || requestParameters.adminBoardDecisionId === undefined) {
            throw new runtime.RequiredError('adminBoardDecisionId','Required parameter requestParameters.adminBoardDecisionId was null or undefined when calling apiFileManagerDownloadAdminBoardDecisionAdminBoardDecisionIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FileManager/DownloadAdminBoardDecision/{adminBoardDecisionId}`.replace(`{${"adminBoardDecisionId"}}`, encodeURIComponent(String(requestParameters.adminBoardDecisionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiFileManagerDownloadAdminBoardDecisionAdminBoardDecisionIdGet(requestParameters: ApiFileManagerDownloadAdminBoardDecisionAdminBoardDecisionIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiFileManagerDownloadAdminBoardDecisionAdminBoardDecisionIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFileManagerDownloadAncFileStudyProgramIdGetRaw(requestParameters: ApiFileManagerDownloadAncFileStudyProgramIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.studyProgramId === null || requestParameters.studyProgramId === undefined) {
            throw new runtime.RequiredError('studyProgramId','Required parameter requestParameters.studyProgramId was null or undefined when calling apiFileManagerDownloadAncFileStudyProgramIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FileManager/DownloadAncFile/{studyProgramId}`.replace(`{${"studyProgramId"}}`, encodeURIComponent(String(requestParameters.studyProgramId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiFileManagerDownloadAncFileStudyProgramIdGet(requestParameters: ApiFileManagerDownloadAncFileStudyProgramIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiFileManagerDownloadAncFileStudyProgramIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFileManagerDownloadAracisRequestStudyProgramIdGetRaw(requestParameters: ApiFileManagerDownloadAracisRequestStudyProgramIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.studyProgramId === null || requestParameters.studyProgramId === undefined) {
            throw new runtime.RequiredError('studyProgramId','Required parameter requestParameters.studyProgramId was null or undefined when calling apiFileManagerDownloadAracisRequestStudyProgramIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FileManager/DownloadAracisRequest/{studyProgramId}`.replace(`{${"studyProgramId"}}`, encodeURIComponent(String(requestParameters.studyProgramId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiFileManagerDownloadAracisRequestStudyProgramIdGet(requestParameters: ApiFileManagerDownloadAracisRequestStudyProgramIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiFileManagerDownloadAracisRequestStudyProgramIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFileManagerDownloadExternalEvaluationExternalEvaluationIdGetRaw(requestParameters: ApiFileManagerDownloadExternalEvaluationExternalEvaluationIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.externalEvaluationId === null || requestParameters.externalEvaluationId === undefined) {
            throw new runtime.RequiredError('externalEvaluationId','Required parameter requestParameters.externalEvaluationId was null or undefined when calling apiFileManagerDownloadExternalEvaluationExternalEvaluationIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FileManager/DownloadExternalEvaluation/{externalEvaluationId}`.replace(`{${"externalEvaluationId"}}`, encodeURIComponent(String(requestParameters.externalEvaluationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiFileManagerDownloadExternalEvaluationExternalEvaluationIdGet(requestParameters: ApiFileManagerDownloadExternalEvaluationExternalEvaluationIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiFileManagerDownloadExternalEvaluationExternalEvaluationIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFileManagerDownloadSenateDecisionSenateDecisionIdGetRaw(requestParameters: ApiFileManagerDownloadSenateDecisionSenateDecisionIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.senateDecisionId === null || requestParameters.senateDecisionId === undefined) {
            throw new runtime.RequiredError('senateDecisionId','Required parameter requestParameters.senateDecisionId was null or undefined when calling apiFileManagerDownloadSenateDecisionSenateDecisionIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FileManager/DownloadSenateDecision/{senateDecisionId}`.replace(`{${"senateDecisionId"}}`, encodeURIComponent(String(requestParameters.senateDecisionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiFileManagerDownloadSenateDecisionSenateDecisionIdGet(requestParameters: ApiFileManagerDownloadSenateDecisionSenateDecisionIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiFileManagerDownloadSenateDecisionSenateDecisionIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFileManagerUploadAdminBoardDecisionAdminBoardDecisionIdPostRaw(requestParameters: ApiFileManagerUploadAdminBoardDecisionAdminBoardDecisionIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.adminBoardDecisionId === null || requestParameters.adminBoardDecisionId === undefined) {
            throw new runtime.RequiredError('adminBoardDecisionId','Required parameter requestParameters.adminBoardDecisionId was null or undefined when calling apiFileManagerUploadAdminBoardDecisionAdminBoardDecisionIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/FileManager/UploadAdminBoardDecision/{adminBoardDecisionId}`.replace(`{${"adminBoardDecisionId"}}`, encodeURIComponent(String(requestParameters.adminBoardDecisionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFileManagerUploadAdminBoardDecisionAdminBoardDecisionIdPost(requestParameters: ApiFileManagerUploadAdminBoardDecisionAdminBoardDecisionIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiFileManagerUploadAdminBoardDecisionAdminBoardDecisionIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFileManagerUploadAncFileStudyProgramIdPostRaw(requestParameters: ApiFileManagerUploadAncFileStudyProgramIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.studyProgramId === null || requestParameters.studyProgramId === undefined) {
            throw new runtime.RequiredError('studyProgramId','Required parameter requestParameters.studyProgramId was null or undefined when calling apiFileManagerUploadAncFileStudyProgramIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/FileManager/UploadAncFile/{studyProgramId}`.replace(`{${"studyProgramId"}}`, encodeURIComponent(String(requestParameters.studyProgramId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFileManagerUploadAncFileStudyProgramIdPost(requestParameters: ApiFileManagerUploadAncFileStudyProgramIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiFileManagerUploadAncFileStudyProgramIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFileManagerUploadAracisRequestStudyProgramIdPostRaw(requestParameters: ApiFileManagerUploadAracisRequestStudyProgramIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.studyProgramId === null || requestParameters.studyProgramId === undefined) {
            throw new runtime.RequiredError('studyProgramId','Required parameter requestParameters.studyProgramId was null or undefined when calling apiFileManagerUploadAracisRequestStudyProgramIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/FileManager/UploadAracisRequest/{studyProgramId}`.replace(`{${"studyProgramId"}}`, encodeURIComponent(String(requestParameters.studyProgramId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFileManagerUploadAracisRequestStudyProgramIdPost(requestParameters: ApiFileManagerUploadAracisRequestStudyProgramIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiFileManagerUploadAracisRequestStudyProgramIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFileManagerUploadExternalEvaluationExternalEvaluationIdPostRaw(requestParameters: ApiFileManagerUploadExternalEvaluationExternalEvaluationIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.externalEvaluationId === null || requestParameters.externalEvaluationId === undefined) {
            throw new runtime.RequiredError('externalEvaluationId','Required parameter requestParameters.externalEvaluationId was null or undefined when calling apiFileManagerUploadExternalEvaluationExternalEvaluationIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/FileManager/UploadExternalEvaluation/{externalEvaluationId}`.replace(`{${"externalEvaluationId"}}`, encodeURIComponent(String(requestParameters.externalEvaluationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFileManagerUploadExternalEvaluationExternalEvaluationIdPost(requestParameters: ApiFileManagerUploadExternalEvaluationExternalEvaluationIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiFileManagerUploadExternalEvaluationExternalEvaluationIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFileManagerUploadSenateDecisionSenateDecisionIdPostRaw(requestParameters: ApiFileManagerUploadSenateDecisionSenateDecisionIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.senateDecisionId === null || requestParameters.senateDecisionId === undefined) {
            throw new runtime.RequiredError('senateDecisionId','Required parameter requestParameters.senateDecisionId was null or undefined when calling apiFileManagerUploadSenateDecisionSenateDecisionIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/FileManager/UploadSenateDecision/{senateDecisionId}`.replace(`{${"senateDecisionId"}}`, encodeURIComponent(String(requestParameters.senateDecisionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFileManagerUploadSenateDecisionSenateDecisionIdPost(requestParameters: ApiFileManagerUploadSenateDecisionSenateDecisionIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiFileManagerUploadSenateDecisionSenateDecisionIdPostRaw(requestParameters, initOverrides);
    }

}
