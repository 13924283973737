/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RecomandationStatusEnum {
    Indeplinit = 'INDEPLINIT',
    Neindeplinit = 'NEINDEPLINIT',
    PartialIndeplinit = 'PARTIAL_INDEPLINIT',
    Inaplicabil = 'INAPLICABIL'
}

export function RecomandationStatusEnumFromJSON(json: any): RecomandationStatusEnum {
    return RecomandationStatusEnumFromJSONTyped(json, false);
}

export function RecomandationStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecomandationStatusEnum {
    return json as RecomandationStatusEnum;
}

export function RecomandationStatusEnumToJSON(value?: RecomandationStatusEnum | null): any {
    return value as any;
}

